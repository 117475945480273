import * as React from 'react'

function CloseIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16.001} height={13} viewBox="0 0 16.001 13" {...props}>
      <path
        data-name="Union 47"
        d="M6422.95 2367l-4.95-4.887-4.89 4.887H6410l6.5-6.5-6.5-6.5h3.111l4.89 4.885 4.95-4.886H6426l-6.5 6.5 6.5 6.5z"
        transform="translate(-6410 -2354)"
        fill="CurrentColor"
      />
    </svg>
  )
}

export default CloseIcon
