import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  NewWelcomerAttachedAddon,
  GroupedWelcomerAttachedAddon,
  WelcomerForMePersonalInfo,
  ProgressBarType,
  WelcomerBestOffer,
  WelcomerOffers,
  EnabledLanguage,
  WelcomersDatePrices,
} from '../domain/types'

type WelcomersDate = {
  id: string
  age: number
  date: string | null
  dateFormat: string | null
  month: number | null
  priceId: number
}
//
type AttachedAddonType = {
  count: number
  addon: NewWelcomerAttachedAddon
}
//
type GroupedAttachedAddonType = {
  count: number
  addon: GroupedWelcomerAttachedAddon
}

export type WelcomerFlowFilter = {
  betterOffer: boolean
  productCategory: string
  singleDates: {
    start: Date | string | null
    end: Date | string | null
  }
  insuranceDays: number
  currency?: string
  limit?: number | null
  franchise?: number | undefined
  welcomersDate: WelcomersDate[]
  welcomersDatePrices: WelcomersDatePrices[]
  chooseMethod: string
  promoCode: string
  promoCodeValue: string
  discountUsedSuccessfully: boolean
  selectedCompaniesIds: number[] | []
  selectedCompanies: WelcomerOffers[] | undefined
  leftCompanyIds: number[] | []
  uniqueId: string
  offer?: WelcomerBestOffer
  companyOffers?: WelcomerOffers[]
  attachedAddons?: AttachedAddonType[]
  groupedAttachedAddons?: GroupedAttachedAddonType[]
  offerTotalPrice?: number
  packageIds: number[]
  currencyId: number
  limitId: number
  IsCompanyFromUser: boolean
  allPrices: number[]
  fixedOffer: boolean
}
//
export type WelcomerCheckoutFlow = {
  forWho: string
  insurer: Partial<WelcomerForMePersonalInfo>
  welcomers?: Partial<WelcomerForMePersonalInfo>[]
  orderId: number
  totalPrice: number
}
//
export type WelcomerState = {
  filter: Partial<WelcomerFlowFilter>
  checkout: WelcomerCheckoutFlow
  screenHeader: {
    totalCount: number
    passedCount: number
  }
  progress: ProgressBarType[]
}

const realInitialState: WelcomerState = {
  filter: {
    productCategory: 'welcomer-to-georgia',
    singleDates: {
      start: null,
      end: null,
    },
    insuranceDays: 0,
    currency: 'gel',
    limit: null,
    welcomersDate: [
      {
        id: '',
        age: 0,
        date: '',
        dateFormat: '',
        month: null,
        priceId: 0,
      },
    ],
    welcomersDatePrices: [
      {
        priceId: 0,
        age: 0,
        date: '',
        welcomerAgeGroupId: 0,
        welcomerDayGroupId: 0,
        premium: 0
      },
    ],
    chooseMethod: '',
    promoCode: '',
    promoCodeValue: '',
    discountUsedSuccessfully: false,
    selectedCompaniesIds: [],
    selectedCompanies: undefined,
    leftCompanyIds: [],
    offer: undefined,
    companyOffers: [],
    attachedAddons: [],
    groupedAttachedAddons: [],
    offerTotalPrice: undefined,
    packageIds: undefined,
    currencyId: undefined,
    limitId: undefined,
    IsCompanyFromUser: false,
    allPrices: [],
    fixedOffer: false,
  },
  //
  checkout: {
    forWho: '',
    insurer: {
      cityzenship: 'GE',
    },
    welcomers: undefined,
    orderId: 0,
    totalPrice: 0,
  },
  screenHeader: {
    totalCount: 10,
    passedCount: 1,
  },
  progress: [
    {
      id: 2,
      title: '',
      url: '/welcomer-start-date',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 3,
      title: '',
      url: '/welcomer-currency-and-limit',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 4,
      title: '',
      url: '/welcomer-add-travelers',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 5,
      title: '',
      url: '/welcomer-choose-method',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 6,
      title: '',
      url: '/welcomer-offers',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 7,
      title: '',
      url: '/welcomer-offer',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 8,
      title: '',
      url: '/welcomer-who-do-you-insure',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 9,
      title: '',
      url: '/welcomer-for-me',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 10,
      title: '',
      url: '/welcomer-for-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 11,
      title: '',
      url: '/welcomer-insurer',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 12,
      title: '',
      url: '/welcomer-for-me-and-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 13,
      title: '',
      url: '/welcomer-check-information',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 14,
      title: '',
      url: '/welcomer-policy-registration',
      active: false,
      passed: false,
      isVisible: true,
    },
  ],
}

//
const initialState = realInitialState
//
const slice = createSlice({
  name: 'welcomerSlice',
  initialState,
  reducers: {
    // BEGIN filter actions
    clearWelcomerState: (state: WelcomerState, actionPayload: PayloadAction<undefined>) => {
      return {
        ...initialState,
      }
    },
    //
    //
    setUpdateWelcomerProgress: (state: WelcomerState, action: PayloadAction<{ title: string; id: number }>) => {
      //
      const { title, id } = action.payload
      //
      const updated = state.progress.map((item) => (item.id === id ? { ...item, title } : item))
      //
      const changeStatus = updated.map((item) => {
        //
        if (item.id === id) {
          // If the item's id matches the provided id, update accordingly
          return {
            ...item,
            active: true,
            passed: true,
          }
        } else if (item.id && item.id < id) {
          // If the item's id is less than the provided id, mark as passed
          return {
            ...item,
            active: false,
            passed: true,
          }
        } else {
          // If the item's id is greater than the provided id, mark as not passed or active
          return {
            ...item,
            active: false,
            passed: false,
          }
        }
      })
      //
      state.progress = changeStatus
      //
    },
    //
    setWelcomerVisibleStep: (state: WelcomerState, action: PayloadAction<{ id: number; isVisible: boolean }>) => {
      //
      const { id, isVisible } = action.payload
      //
      const updatedProgress = state.progress?.map((item) => (item.id === id ? { ...item, isVisible } : item))
      //
      state.progress = updatedProgress
    },
    //
    setProductCategory: (
      state: WelcomerState,
      { payload: productCategory }: PayloadAction<WelcomerState['filter']['productCategory']>,
    ) => {
      state.filter = {
        ...state.filter,
        productCategory,
      }
    },
    //
    //
    setUniquedId: (state: WelcomerState, { payload: uniqueId }: PayloadAction<WelcomerState['filter']['uniqueId']>) => {
      state.filter = {
        ...state.filter,
        uniqueId,
      }
    },
    //
    setOfferTotalPrice: (
      state: WelcomerState,
      { payload: offerTotalPrice }: PayloadAction<WelcomerState['filter']['offerTotalPrice']>,
    ) => {
      state.filter = {
        ...state.filter,
        offerTotalPrice,
      }
    },
    //
    //
    setHeaderPassedCount: (
      state: WelcomerState,
      { payload: passedCount }: PayloadAction<WelcomerState['screenHeader']['passedCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        passedCount,
      }
    },
    //
    setHeaderTotalCount: (
      state: WelcomerState,
      { payload: totalCount }: PayloadAction<WelcomerState['screenHeader']['totalCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        totalCount,
      }
    },
    //
    //
    setSingleDates: (
      state: WelcomerState,
      { payload: singleDates }: PayloadAction<WelcomerState['filter']['singleDates']>,
    ) => {
      state.filter = {
        ...state.filter,
        singleDates,
      }
    },
    //
    setInsuranceDays: (
      state: WelcomerState,
      { payload: insuranceDays }: PayloadAction<WelcomerState['filter']['insuranceDays']>,
    ) => {
      state.filter = {
        ...state.filter,
        insuranceDays,
      }
    },
    //
    setCurrency: (state: WelcomerState, { payload: currency }: PayloadAction<WelcomerState['filter']['currency']>) => {
      state.filter = {
        ...state.filter,
        currency,
      }
    },
    //
    setLimit: (state: WelcomerState, { payload: limit }: PayloadAction<WelcomerState['filter']['limit']>) => {
      state.filter = {
        ...state.filter,
        limit,
      }
    },
    //
    setOffer: (state: WelcomerState, { payload: offer }: PayloadAction<WelcomerState['filter']['offer']>) => {
      state.filter = {
        ...state.filter,
        offer,
      }
    },
    //
    setFranchise: (
      state: WelcomerState,
      { payload: franchise }: PayloadAction<WelcomerState['filter']['franchise']>,
    ) => {
      state.filter = {
        ...state.filter,
        franchise,
      }
    },
    //
    addEmptyWelcomers: (
      state: WelcomerState,
      { payload: welcomersDate }: PayloadAction<WelcomerState['filter']['welcomersDate']>,
    ) => {
      state.filter = {
        ...state.filter,
        welcomersDate: [
          ...(state.filter.welcomersDate || []),
          {
            id: '',
            age: 0,
            date: null,
            dateFormat: null,
            month: null,
            priceId: 0,
          },
        ],
      }
    },
    //
    //
    updateWelcomersAtIndex: (
      state: WelcomerState,
      { payload }: PayloadAction<{ welcomerIndex: number; newValue: WelcomersDate }>,
    ) => {
      state.filter = {
        ...state.filter,
        welcomersDate: (state.filter.welcomersDate || []).map((item, index) => {
          return index === payload.welcomerIndex ? payload.newValue : item
        }),
      }
    },
    //
    updateWelcomerDatesPrices: (
      state: WelcomerState,
      { payload: welcomersDatePrices }: PayloadAction<WelcomerState['filter']['welcomersDatePrices']>,
    ) => {
      state.filter = {
        ...state.filter,
        welcomersDatePrices
      }
    },
    //
    removeWelcomersAtIndex: (state: WelcomerState, { payload: welcomerIndex }: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        welcomersDate: (state.filter.welcomersDate || []).filter((item, index) => index !== welcomerIndex),
      }
    },
    //
    //
    setChooseMethod: (
      state: WelcomerState,
      { payload: chooseMethod }: PayloadAction<WelcomerState['filter']['chooseMethod']>,
    ) => {
      state.filter = {
        ...state.filter,
        chooseMethod,
      }
    },
    //
    setPromoCodeValue: (
      state: WelcomerState,
      { payload: promoCodeValue }: PayloadAction<WelcomerState['filter']['promoCodeValue']>,
    ) => {
      state.filter = {
        ...state.filter,
        promoCodeValue,
      }
    },
    //
    setPromoCode: (state: WelcomerState, { payload: promoCode }: PayloadAction<WelcomerState['filter']['promoCode']>) => {
      state.filter = {
        ...state.filter,
        promoCode,
      }
    },
    //    
    setDiscountUsedSuccessfully: (
      state: WelcomerState,
      { payload: discountUsedSuccessfully }: PayloadAction<WelcomerState['filter']['discountUsedSuccessfully']>,
    ) => {
      state.filter = {
        ...state.filter,
        discountUsedSuccessfully,
      }
    },
    // 
    setCompanyOffers: (state: WelcomerState, { payload: companyOffers }: PayloadAction<WelcomerOffers[]>) => {
      state.filter = {
        ...state.filter,
        companyOffers,
      }
    },
    //
    setCompanyId: (
      state: WelcomerState,
      { payload: selectedCompaniesIds }: PayloadAction<WelcomerState['filter']['selectedCompaniesIds']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedCompaniesIds,
      }
    },
    //
    setSelectedCompanies: (
      state: WelcomerState,
      { payload: selectedCompanies }: PayloadAction<WelcomerState['filter']['selectedCompanies']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedCompanies,
      }
    },
    //
    setLeftCompanyIds: (
      state: WelcomerState,
      { payload: leftCompanyIds }: PayloadAction<WelcomerState['filter']['leftCompanyIds']>,
    ) => {
      state.filter = {
        ...state.filter,
        leftCompanyIds,
      }
    },
    //
    setPackageIds: (
      state: WelcomerState,
      { payload: packageIds }: PayloadAction<WelcomerState['filter']['packageIds']>,
    ) => {
      state.filter = {
        ...state.filter,
        packageIds,
      }
    },
    //
    setAddon: (state: WelcomerState, { payload: { addon, count } }: PayloadAction<AttachedAddonType>) => {
      //
      if (state.filter.attachedAddons && state.filter.attachedAddons.length === 0) {
        state.filter = {
          ...state.filter,
          attachedAddons: [{ addon: addon, count: count }],
        }
      }
      //
      if (state.filter.attachedAddons && state.filter.attachedAddons.find((x) => x.addon.addonId !== addon.addonId)) {
        state.filter = {
          ...state.filter,
          attachedAddons: [...(state.filter.attachedAddons || []), { addon: addon, count: count }],
        }
      }
    },
    //
    setGroupAddon: (state: WelcomerState, { payload: { addon, count } }: PayloadAction<GroupedAttachedAddonType>) => {
      //
      if (state.filter.groupedAttachedAddons && state.filter.groupedAttachedAddons.length === 0) {
        state.filter = {
          ...state.filter,
          groupedAttachedAddons: [{ addon: addon, count: count }],
        }
      }
      //
      if (
        state.filter.groupedAttachedAddons &&
        state.filter.groupedAttachedAddons.find((x) => x.addon.id !== addon.id)
      ) {
        state.filter = {
          ...state.filter,
          groupedAttachedAddons: [...(state.filter.groupedAttachedAddons || []), { addon: addon, count: count }],
        }
      }
    },
    //
    updateAddon: (state: WelcomerState, action: PayloadAction<{ addonId: number; count: number }>) => {
      const { addonId, count } = action.payload
      //
      const updatedObjects = state.filter.attachedAddons?.map((obj) =>
        obj.addon.addonId === addonId ? { ...obj, count: count } : obj,
      )
      //
      state.filter.attachedAddons = updatedObjects
    },
    //
    updateGroupAddon: (state: WelcomerState, action: PayloadAction<{ groupId: number; count: number }>) => {
      const { groupId, count } = action.payload
      //
      const updatedObjects = state.filter.groupedAttachedAddons?.map((obj) =>
        obj.addon.id === groupId ? { ...obj, count: count } : obj,
      )
      //
      state.filter.groupedAttachedAddons = updatedObjects
    },
    //
    clearAddon: (
      state: WelcomerState,
      { payload: attachedAddons }: PayloadAction<WelcomerState['filter']['attachedAddons']>,
    ) => {
      state.filter.attachedAddons = attachedAddons
    },
    //
    //
    clearGroupAddon: (
      state: WelcomerState,
      { payload: groupedAttachedAddons }: PayloadAction<WelcomerState['filter']['groupedAttachedAddons']>,
    ) => {
      state.filter.groupedAttachedAddons = groupedAttachedAddons
    },
    //
    removeAddon: (state: WelcomerState, { payload: addonId }: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        attachedAddons: state.filter.attachedAddons?.filter((x) => x.addon.addonId !== addonId),
      }
    },
    //
    removeGroupAddon: (state: WelcomerState, { payload: groupId }: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        groupedAttachedAddons: state.filter.groupedAttachedAddons?.filter((x) => x.addon.id !== groupId),
      }
    },
    //
    setCurrencyId: (
      state: WelcomerState,
      { payload: currencyId }: PayloadAction<WelcomerState['filter']['currencyId']>,
    ) => {
      state.filter = {
        ...state.filter,
        currencyId,
      }
    },
    //
    setLimitId: (state: WelcomerState, { payload: limitId }: PayloadAction<WelcomerState['filter']['limitId']>) => {
      state.filter = {
        ...state.filter,
        limitId,
      }
    },
    //
    setIsCompanyFromUser: (
      state: WelcomerState,
      { payload: IsCompanyFromUser }: PayloadAction<WelcomerState['filter']['IsCompanyFromUser']>,
    ) => {
      state.filter = {
        ...state.filter,
        IsCompanyFromUser,
      }
    },
    //
    setFixedOffer: (
      state: WelcomerState,
      { payload: fixedOffer }: PayloadAction<WelcomerState['filter']['fixedOffer']>,
    ) => {
      state.filter = {
        ...state.filter,
        fixedOffer,
      }
    },
    //
    setAllPrices: (
      state: WelcomerState,
      { payload: allPrices }: PayloadAction<WelcomerState['filter']['allPrices']>,
    ) => {
      state.filter = {
        ...state.filter,
        allPrices,
      }
    },
    //
    // END filter actions
    //
    //
    //
    //
    //
    // BEGIN checkout actions
    setForWho: (state: WelcomerState, { payload: forWho }: PayloadAction<WelcomerState['checkout']['forWho']>) => {
      state.checkout = {
        ...state.checkout,
        forWho,
      }
    },
    //
    removeWelcomersObjA: (state: WelcomerState, { payload: welcomerIndex }: PayloadAction<number>) => {
      //
      state.checkout = {
        ...state.checkout,
        welcomers: (state.checkout.welcomers || []).filter((item, index) => index !== welcomerIndex),
      }
    },
    //
    setWelcomers: (
      state: WelcomerState,
      { payload: welcomers }: PayloadAction<WelcomerState['checkout']['welcomers']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        welcomers: welcomers,
      }
    },
    //
    setInsurerInfo: (
      state: WelcomerState,
      action: PayloadAction<{ fieldName: keyof WelcomerState['checkout']['insurer']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insurer[fieldName] = fieldValue
    },
    //
    setInsurerTranslationInfo: (
      state: WelcomerState,
      action: PayloadAction<{
        fieldName: keyof { firstName: string; lastName: string }
        language: EnabledLanguage
        fieldValue: any
      }>,
    ) => {
      const { fieldName, fieldValue, language } = action.payload
      state.checkout.insurer = {
        ...state.checkout.insurer,
        translations: {
          ...state.checkout.insurer.translations,
          [language]: {
            ...state.checkout.insurer.translations?.[language],
            [fieldName]: fieldValue,
          },
        },
      }
    },
    //
    setInsurerObj: (
      state: WelcomerState,
      { payload: insurer }: PayloadAction<WelcomerState['checkout']['insurer']>,
    ) => {
      state.checkout.insurer = insurer
    },
    //
    setOrderId: (state: WelcomerState, { payload: orderId }: PayloadAction<WelcomerState['checkout']['orderId']>) => {
      state.checkout = {
        ...state.checkout,
        orderId,
      }
    },
    //
    setTotalPrice: (
      state: WelcomerState,
      { payload: totalPrice }: PayloadAction<WelcomerState['checkout']['totalPrice']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        totalPrice,
      }
    },
  },
  //
})

export const {
  // BEGIN filter actions
  clearWelcomerState,
  setUpdateWelcomerProgress,
  setWelcomerVisibleStep,
  setProductCategory,
  setUniquedId,
  setHeaderPassedCount,
  setHeaderTotalCount,
  setSingleDates,
  setInsuranceDays,
  setCurrency,
  setLimit,
  setFranchise,
  setOffer,
  setCompanyOffers,
  addEmptyWelcomers,
  updateWelcomersAtIndex,
  updateWelcomerDatesPrices,
  removeWelcomersAtIndex,
  setChooseMethod,
  setCompanyId,
  setLeftCompanyIds,
  setAddon,
  updateAddon,
  clearAddon,
  removeAddon,
  setGroupAddon,
  clearGroupAddon,
  updateGroupAddon,
  removeGroupAddon,
  setOfferTotalPrice,
  setLimitId,
  setCurrencyId,
  setPackageIds,
  setSelectedCompanies,
  setIsCompanyFromUser,
  setAllPrices,
  setFixedOffer,
  setPromoCode,
  setPromoCodeValue,
  setDiscountUsedSuccessfully,
  // END filter actions
  //
  // BEGIN checkout actions
  setForWho,
  setInsurerInfo,
  setInsurerTranslationInfo,
  setInsurerObj,
  setOrderId,
  setTotalPrice,
  removeWelcomersObjA,
  setWelcomers,
  // END checkout actions
} = slice.actions

export default slice.reducer
