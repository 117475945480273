import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type BusinessProductCategory = {
  productType: string
  title: string
  body: string
  image: string
}

export type CompanyInfoTypes = {
  companyName: string
  companyID: string
  companyActivity: string
  employeeNumber: number
  insuredEmployee: number
  managementNumber: number
  employeeAverageAge: number
  genderBalance: {
    genderMale: string,
    genderFemale: string
  }
  minPriceInsurance: number
  insurancePayer: string
  insuredRecently: boolean
  insuredCompanyName: string
  contractRenewal: string
  carBrand: string
  carModel: string
  manufactoreYear: number
  purpose: string
  marketValue: number
  currency: string
  cadastralCode: string
  propertyPurpose: string
  propertyType: string
  propertyContents: boolean
  propertyAddress: string
  insuranceProduct: string
  comment: string
  firstName: string
  lastName: string
  position: string
  email: string
  mobile: string
}

export type SliceType = {
  productType: string
  companyInfo: Partial<CompanyInfoTypes>
}

const realInitialState: SliceType = {
  productType: '',
  companyInfo: {}
}

const initialState = realInitialState

const slice = createSlice({
  name: 'businessSlice',
  initialState,
  reducers: {
    setProductType: (state: SliceType, { payload: productType }: PayloadAction<SliceType['productType']>) => {
      state.productType = productType
    },
    // 
    setCompanyFields: (
      state: SliceType,
      action: PayloadAction<{ fieldName: keyof SliceType['companyInfo']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.companyInfo[fieldName] = fieldValue
    },
    clearCompanyInfo: (
      state: SliceType
    ) => {
      state.companyInfo = {}
    }
  },
})
// 
// 
export const { setProductType, setCompanyFields, clearCompanyInfo } = slice.actions

export default slice.reducer
