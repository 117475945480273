import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HealthOffer, EnabledLanguage, HealthForMePersonalInfo, ProgressBarType } from '../domain/types'
//

export type HealthFlowFilter = {
  betterOffer: boolean
  dates: {
    start: Date | string | null
    end: Date | string | null
  }
  productCategory: string
  currency?: string
  limit?: number | null
  // franchise?: number | undefined
  chooseMethod: string
  selectedCompanies: number[] | []
  selectedPackageServices: number[] | []
  leftCompanyIds: number[] | []
  servicePriorities: number[] | []
  priceRanges: string[] | []
  priorities: number[] | []
  paymentSchedule: {
    id: number | null
    type: string | null
  }
  paymentScheduleId: number
  uniqueId: string
  offer?: HealthOffer
  companyOffers?: HealthOffer[]
  offerTotalPrice?: number
}
//
//
export type HealthCheckoutFlow = {
  forWho: string
  insurer: Partial<HealthForMePersonalInfo>
  insured?: Partial<HealthForMePersonalInfo>[]
  orderId: number
  totalPrice: number
}
//
//
export type HealthState = {
  filter: Partial<HealthFlowFilter>
  checkout: HealthCheckoutFlow
  screenHeader: {
    totalCount: number
    passedCount: number
  }
  progress: ProgressBarType[]
}
//
//
//
//
const realInitialState: HealthState = {
  filter: {
    productCategory: '',
    currency: '',
    limit: null,
    selectedPackageServices: [],
    selectedCompanies: [],
    servicePriorities: [],
    chooseMethod: '',
    //
    betterOffer: false,
    leftCompanyIds: [],
    paymentSchedule: {
      id: 2,
      type: 'MONTHLY',
    },
    offer: {
      brandName: {
        en: {
          title: 'Ardi',
        },
        ge: {
          title: 'არდი',
        },
      },
      companyId: 2,
      companyLogo: 'https://d3cd9cyzr3ifxh.cloudfront.net/icons/1o.b9daovm4p8-Logo---Ardi.svg',
      companyName: 'არდის სადაზღვევო',
      companySlug: 'ardi',
      isBest: true,
      healthPackageId: 2,
      productConditions: '',
      productWording: 'https://d3cd9cyzr3ifxh.cloudfront.net/files/18.n5i2mhvc2o-ARDI---Travel-Wording.pdf',
      healthRisks: [
        {
          healthRiskId: '1',
        },
        {
          healthRiskId: '2',
        },
      ],
      packageServices: {
        ambulatory: {
          limit: '40',
          payment: '1000',
        },
        medicines: {
          limit: '40',
          payment: '1000',
        },
        hospitalization: {
          limit: '100',
          payment: '3000',
        },
        dentistry: {
          limit: '50',
          payment: '5000',
        },
      },
      services: {
        hospitalization: {
          covered: true,
          waitingPeriod: 12,
        },
        pregnancy: {
          covered: false,
          waitingPeriod: 12,
        },
        medicines: {
          covered: true,
          waitingPeriod: 6,
        },
        dayHospitalization: {
          covered: true,
          waitingPeriod: 1,
        },
      },
      calculatedPrices: {
        monthly: 50,
        annual: 600,
      },
    },
    companyOffers: [
      {
        brandName: {
          en: {
            title: 'IRAO',
          },
          ge: {
            title: 'ირაო',
          },
        },
        companyId: 1,
        companyLogo: 'https://d3cd9cyzr3ifxh.cloudfront.net/icons/2p.na8rror59g-Logo---IRAO.svg',
        companyName: 'ირაოს სადაზღვევო',
        companySlug: 'irao',
        isBest: false,
        healthPackageId: 1,
        productConditions: '',
        productWording: 'https://d3cd9cyzr3ifxh.cloudfront.net/files/21.hrtnmfpgpg-Travel-IRAO---Wording---10.2022.pdf',
        healthRisks: [
          {
            healthRiskId: '1',
          },
          {
            healthRiskId: '2',
          },
        ],
        packageServices: {
          ambulatory: {
            limit: '50',
            payment: '1000',
          },
          medicines: {
            limit: '60',
            payment: '1000',
          },
          hospitalization: {
            limit: '100',
            payment: '15000',
          },
          dentistry: {
            limit: '50',
            payment: '1000',
          },
        },
        services: {
          hospitalization: {
            covered: true,
            waitingPeriod: 12,
          },
          pregnancy: {
            covered: false,
            waitingPeriod: 12,
          },
          medicines: {
            covered: true,
            waitingPeriod: 6,
          },
          dayHospitalization: {
            covered: true,
            waitingPeriod: 1,
          },
        },
        calculatedPrices: {
          monthly: 55,
          annual: 660,
        },
      },
      {
        brandName: {
          en: {
            title: 'Ardi',
          },
          ge: {
            title: 'არდი',
          },
        },
        companyId: 2,
        companyLogo: 'https://d3cd9cyzr3ifxh.cloudfront.net/icons/1o.b9daovm4p8-Logo---Ardi.svg',
        companyName: 'არდის სადაზღვევო',
        companySlug: 'ardi',
        isBest: true,
        healthPackageId: 2,
        productConditions: '',
        productWording: 'https://d3cd9cyzr3ifxh.cloudfront.net/files/18.n5i2mhvc2o-ARDI---Travel-Wording.pdf',
        healthRisks: [
          {
            healthRiskId: '1',
          },
          {
            healthRiskId: '2',
          },
        ],
        packageServices: {
          ambulatory: {
            limit: '40',
            payment: '1000',
          },
          medicines: {
            limit: '40',
            payment: '1000',
          },
          hospitalization: {
            limit: '100',
            payment: '3000',
          },
          dentistry: {
            limit: '50',
            payment: '5000',
          },
        },
        services: {
          hospitalization: {
            covered: true,
            waitingPeriod: 12,
          },
          pregnancy: {
            covered: false,
            waitingPeriod: 12,
          },
          medicines: {
            covered: true,
            waitingPeriod: 6,
          },
          dayHospitalization: {
            covered: true,
            waitingPeriod: 1,
          },
        },
        calculatedPrices: {
          monthly: 44,
          annual: 528,
        },
      },
      {
        brandName: {
          en: {
            title: 'Imedi L',
          },
          ge: {
            title: 'იმედი ლ',
          },
        },
        companyId: 3,
        companyLogo: 'https://d3cd9cyzr3ifxh.cloudfront.net/icons/2i.02q9f90mgg-Imedi-L.svg',
        companyName: 'სს სადაზღვევო კომპანია იმედი ელ',
        companySlug: 'imediL',
        isBest: false,
        healthPackageId: 3,
        productConditions: '',
        productWording: 'https://d3cd9cyzr3ifxh.cloudfront.net/files/f.kbji1njjkm-Wording_Imedi-L---50-000-USD.pdf',
        healthRisks: [
          {
            healthRiskId: '1',
          },
          {
            healthRiskId: '2',
          },
        ],
        packageServices: {
          ambulatory: {
            limit: '50',
            payment: '1000',
          },
          medicines: {
            limit: '60',
            payment: '1000',
          },
          hospitalization: {
            limit: '100',
            payment: '15000',
          },
          dentistry: {
            limit: '50',
            payment: '1000',
          },
        },
        services: {
          hospitalization: {
            covered: true,
            waitingPeriod: 12,
          },
          pregnancy: {
            covered: true,
            waitingPeriod: 12,
          },
          medicines: {
            covered: false,
            waitingPeriod: 6,
          },
          dayHospitalization: {
            covered: true,
            waitingPeriod: 1,
          },
        },
        calculatedPrices: {
          monthly: 50,
          annual: 600,
        },
      },
    ],
    offerTotalPrice: undefined,
  },
  //
  checkout: {
    forWho: '',
    insurer: {
      cityzenship: 'GE',
    },
    insured: undefined,
    orderId: 0,
    totalPrice: 0,
  },
  screenHeader: {
    totalCount: 9,
    passedCount: 1,
  },
  progress: [
    {
      id: 1,
      title: '',
      url: '/health-compare-companies',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 2,
      title: '',
      url: '/health-priority',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 3,
      title: '',
      url: '/health-start-date',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 4,
      title: '',
      url: '/health-choose-method',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 5,
      title: '',
      url: '/health-offers',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 6,
      title: '',
      url: '/health-offer',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 7,
      title: '',
      url: '/health-who-do-you-insure',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 8,
      title: '',
      url: '/health-for-me',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 9,
      title: '',
      url: '/health-for-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 10,
      title: '',
      url: '/health-insurer',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 11,
      title: '',
      url: '/health-for-me-and-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 12,
      title: '',
      url: '/health-check-information',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 13,
      title: '',
      url: '/health-policy-registration',
      active: false,
      passed: false,
      isVisible: true,
    },
  ],
}
//
//
const initialState = realInitialState
//
//
const slice = createSlice({
  name: 'healthSlice',
  initialState,
  reducers: {
    //
    // BEGIN filter actions
    clearHealthStore: (state: HealthState, actionPayload: PayloadAction<undefined>) => {
      state.filter = initialState.filter
    },
    //
    setUpdateHealthProgress: (state: HealthState, action: PayloadAction<{title: string, id: number}>) => {
      //
      const { title, id } = action.payload
      //
      const updated = state.progress.map((item) => (item.id === id ? { ...item, title } : item))
      //
      const changeStatus = updated.map((item) => {
        //
        if (item.id === id) {
          // If the item's id matches the provided id, update accordingly
          return {
            ...item,
            active: true,
            passed: true,
          }
        } else if (item.id && item.id < id) {
          // If the item's id is less than the provided id, mark as passed
          return {
            ...item,
            active: false,
            passed: true,
          }
        } else {
          // If the item's id is greater than the provided id, mark as not passed or active
          return {
            ...item,
            active: false,
            passed: false,
          }
        }
      })
      //
      state.progress = changeStatus
      //
    },
    // 
    setHealthVisibleStep: (state: HealthState, action: PayloadAction<{ id: number; isVisible: boolean }>) => {
      // 
      const { id, isVisible } = action.payload
      //
      const updatedProgress = state.progress?.map((item) => (item.id === id ? { ...item, isVisible } : item))
      //
      state.progress = updatedProgress
    },
    //
    setProductCategory: (
      state: HealthState,
      { payload: productCategory }: PayloadAction<HealthState['filter']['productCategory']>,
    ) => {
      state.filter = {
        ...state.filter,
        productCategory,
      }
    },
    //
    setUniquedId: (state: HealthState, { payload: uniqueId }: PayloadAction<HealthState['filter']['uniqueId']>) => {
      state.filter = {
        ...state.filter,
        uniqueId,
      }
    },
    //
    setOfferTotalPrice: (
      state: HealthState,
      { payload: offerTotalPrice }: PayloadAction<HealthState['filter']['offerTotalPrice']>,
    ) => {
      state.filter = {
        ...state.filter,
        offerTotalPrice,
      }
    },
    //
    //
    setHeaderPassedCount: (
      state: HealthState,
      { payload: passedCount }: PayloadAction<HealthState['screenHeader']['passedCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        passedCount,
      }
    },
    //
    setHeaderTotalCount: (
      state: HealthState,
      { payload: totalCount }: PayloadAction<HealthState['screenHeader']['totalCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        totalCount,
      }
    },
    //
    //
    setBetterOffer: (
      state: HealthState,
      { payload: betterOffer }: PayloadAction<HealthState['filter']['betterOffer']>,
    ) => {
      state.filter = {
        ...state.filter,
        betterOffer,
      }
    },
    //
    //
    //
    setCurrency: (state: HealthState, { payload: currency }: PayloadAction<HealthState['filter']['currency']>) => {
      state.filter = {
        ...state.filter,
        currency,
      }
    },
    //
    setLimit: (state: HealthState, { payload: limit }: PayloadAction<HealthState['filter']['limit']>) => {
      state.filter = {
        ...state.filter,
        limit,
      }
    },
    //
    setOffer: (state: HealthState, { payload: offer }: PayloadAction<HealthState['filter']['offer']>) => {
      state.filter = {
        ...state.filter,
        offer,
      }
    },
    //
    setChooseMethod: (
      state: HealthState,
      { payload: chooseMethod }: PayloadAction<HealthState['filter']['chooseMethod']>,
    ) => {
      state.filter = {
        ...state.filter,
        chooseMethod,
      }
    },
    //
    setCompanyOffers: (state: HealthState, { payload: companyOffers }: PayloadAction<HealthOffer[]>) => {
      state.filter = {
        ...state.filter,
        companyOffers,
      }
    },
    //
    setCompanyId: (
      state: HealthState,
      { payload: selectedCompanies }: PayloadAction<HealthState['filter']['selectedCompanies']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedCompanies,
      }
    },
    //
    setSelectedPackageServices: (
      state: HealthState,
      { payload: selectedPackageServices }: PayloadAction<HealthState['filter']['selectedPackageServices']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedPackageServices,
      }
    },
    setServicePriorities: (
      state: HealthState,
      { payload: servicePriorities }: PayloadAction<HealthState['filter']['servicePriorities']>,
    ) => {
      state.filter = {
        ...state.filter,
        servicePriorities,
      }
    },
    //
    //
    setLeftCompanyIds: (
      state: HealthState,
      { payload: leftCompanyIds }: PayloadAction<HealthState['filter']['leftCompanyIds']>,
    ) => {
      state.filter = {
        ...state.filter,
        leftCompanyIds,
      }
    },
    //
    setPriorities: (
      state: HealthState,
      { payload: priorities }: PayloadAction<HealthState['filter']['priorities']>,
    ) => {
      state.filter = {
        ...state.filter,
        priorities,
      }
    },
    //
    setPriceRanges: (
      state: HealthState,
      { payload: priceRanges }: PayloadAction<HealthState['filter']['priceRanges']>,
    ) => {
      state.filter = {
        ...state.filter,
        priceRanges,
      }
    },
    setPaymentScheduleArr: (
      state: HealthState,
      { payload: paymentSchedule }: PayloadAction<HealthState['filter']['paymentSchedule']>,
    ) => {
      state.filter = {
        ...state.filter,
        paymentSchedule,
      }
    },
    setPaymentSchedule: (
      state: HealthState,
      { payload: paymentScheduleId }: PayloadAction<HealthState['filter']['paymentScheduleId']>,
    ) => {
      state.filter = {
        ...state.filter,
        paymentScheduleId,
      }
    },
    //
    //
    //
    // END filter actions
    //
    //
    //
    //
    // BEGIN checkout actions
    setForWho: (state: HealthState, { payload: forWho }: PayloadAction<HealthState['checkout']['forWho']>) => {
      state.checkout = {
        ...state.checkout,
        forWho,
      }
    },
    //
    setInsured: (state: HealthState, { payload: insured }: PayloadAction<HealthState['checkout']['insured']>) => {
      state.checkout = {
        ...state.checkout,
        insured: insured,
      }
    },
    //
    setInsurerInfo: (
      state: HealthState,
      action: PayloadAction<{ fieldName: keyof HealthState['checkout']['insurer']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insurer[fieldName] = fieldValue
    },
    //
    setInsurerObj: (state: HealthState, { payload: insurer }: PayloadAction<HealthState['checkout']['insurer']>) => {
      state.checkout.insurer = insurer
    },
    //
    setInsurerTranslationInfo: (
      state: HealthState,
      action: PayloadAction<{
        fieldName: keyof { firstName: string; lastName: string }
        language: EnabledLanguage
        fieldValue: any
      }>,
    ) => {
      const { fieldName, fieldValue, language } = action.payload
      state.checkout.insurer = {
        ...state.checkout.insurer,
        translations: {
          ...state.checkout.insurer.translations,
          [language]: {
            ...state.checkout.insurer.translations?.[language],
            [fieldName]: fieldValue,
          },
        },
      }
    },
    //
    setOrderId: (state: HealthState, { payload: orderId }: PayloadAction<HealthState['checkout']['orderId']>) => {
      state.checkout = {
        ...state.checkout,
        orderId,
      }
    },
    //
    setTotalPrice: (
      state: HealthState,
      { payload: totalPrice }: PayloadAction<HealthState['checkout']['totalPrice']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        totalPrice,
      }
    },
    setDates: (state: HealthState, { payload: dates }: PayloadAction<HealthState['filter']['dates']>) => {
      state.filter = {
        ...state.filter,
        dates,
      }
    },
    //
    // END checkout actions
    //
  },
})

export const {
  // BEGIN filter actions
  setDates,
  clearHealthStore,
  setUpdateHealthProgress,
  setHealthVisibleStep,
  setProductCategory,
  setUniquedId,
  setHeaderPassedCount,
  setHeaderTotalCount,
  setCurrency,
  setLimit,
  setOffer,
  setCompanyOffers,
  setSelectedPackageServices,
  setChooseMethod,
  setCompanyId,
  setLeftCompanyIds,
  setPriorities,
  setPriceRanges,
  setPaymentScheduleArr,
  setPaymentSchedule,
  setBetterOffer,
  setOfferTotalPrice,
  // END filter actions
  //
  // BEGIN checkout actions
  setForWho,
  setInsurerInfo,
  setInsurerTranslationInfo,
  setInsurerObj,
  setOrderId,
  setTotalPrice,
  setInsured,
  // END checkout actions
} = slice.actions

export default slice.reducer
