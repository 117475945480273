import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AbroadOffer,
  EnabledLanguage,
  ForMePersonalInfo,
  GroupedTravelAttachedAddon,
  NewTravelAttachedAddon,
  ProgressBarType,
} from '../domain/types'
//
//
type TravelersDate = {
  age: number
  date: string | null
  dateFormat: string | null
  month: number | null
}
//
type AttachedAddonType = {
  count: number
  addon: NewTravelAttachedAddon
}
//
type GroupedAttachedAddonType = {
  count: number
  addon: GroupedTravelAttachedAddon
}
//
type ProgressType = {
  title: string
  url: string
  active: boolean
  passed: boolean
}
//
//
export type TravelAbroadFlowFilter = {
  betterOffer: boolean
  productCategory: string
  singleOrMulti: string
  singleDates: {
    start: Date | string | null
    end: Date | string | null
  }
  region: string
  multiDays: {
    days: string | number
    validityPeriod: number | string
  }
  multiDates: {
    start: Date | string | null
    end: Date | string | null
  }
  currency?: string
  limit?: number | null
  franchise?: number | undefined
  travelersDate: TravelersDate[]
  chooseMethod: string
  promoCode: string
  promoCodeValue: string
  discountUsedSuccesfully: boolean
  promoCodeUsedSuccessfully: boolean
  selectedCompanies: number[] | []
  leftCompanyIds: number[] | []
  uniqueId: string
  offer?: AbroadOffer
  companyOffers?: AbroadOffer[]
  attachedAddons?: AttachedAddonType[]
  groupedAttachedAddons?: GroupedAttachedAddonType[]
  offerTotalPrice?: number
}
//
//
export type TravelAbroadCheckoutFlow = {
  forWho: string
  insurer: Partial<ForMePersonalInfo>
  travelers?: Partial<ForMePersonalInfo>[]
  orderId: number
  totalPrice: number
}
//
//
export type TravelAbroadState = {
  filter: Partial<TravelAbroadFlowFilter>
  checkout: TravelAbroadCheckoutFlow
  screenHeader: {
    totalCount: number
    passedCount: number
  }
  progress: ProgressBarType[]
}
//
//
//
//
const realInitialState: TravelAbroadState = {
  filter: {
    betterOffer: false,
    productCategory: 'travel-outside-georgia',
    singleOrMulti: '',
    singleDates: {
      start: null,
      end: null,
    },
    region: '',
    multiDays: {
      days: '',
      validityPeriod: '',
    },
    multiDates: {
      start: null,
      end: null,
    },
    currency: '',
    limit: null,
    travelersDate: [
      {
        age: 0,
        date: '',
        dateFormat: '',
        month: null,
      },
    ],
    chooseMethod: '',
    promoCode: "",
    promoCodeValue: "",
    discountUsedSuccesfully: false,
    promoCodeUsedSuccessfully: false,
    selectedCompanies: [],
    leftCompanyIds: [],
    offer: undefined,
    companyOffers: [],
    attachedAddons: [],
    groupedAttachedAddons: [],
    offerTotalPrice: undefined,
  },
  //
  checkout: {
    forWho: '',
    insurer: {
      cityzenship: 'GE',
    },
    travelers: undefined,
    orderId: 0,
    totalPrice: 0,
  },
  screenHeader: {
    totalCount: 11,
    passedCount: 1,
  },
  progress: [
    {
      id: 1,
      title: '',
      url: '/single-or-multi',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 2,
      title: '',
      url: '/single-select-date',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 3,
      title: '',
      url: '/multi-select-date',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 4,
      title: '',
      url: '/select-region',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 5,
      title: '',
      url: '/currency-and-limit',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 6,
      title: '',
      url: '/add-travelers',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 7,
      title: '',
      url: '/choose-method',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 8,
      title: '',
      url: '/travel-offers',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 9,
      title: '',
      url: '/travel-offer',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 10,
      title: '',
      url: '/who-do-you-insure',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 11,
      title: '',
      url: '/abroad-for-me',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 12,
      title: '',
      url: '/abroad-for-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 13,
      title: '',
      url: '/abroad-insurer',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 14,
      title: '',
      url: '/abroad-for-me-and-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 15,
      title: '',
      url: '/abroad-check-information',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 16,
      title: '',
      url: '/policy-registration',
      active: false,
      passed: false,
      isVisible: true,
    },
  ],
}
//
//
const initialState = realInitialState
//
//
const slice = createSlice({
  name: 'travelAbroadSlice',
  initialState,
  reducers: {
    //
    // BEGIN filter actions
    clearAbroadStore: (state: TravelAbroadState, actionPayload: PayloadAction<undefined>) => {
      return {
        ...initialState,
      }
    },
    //
    setUpdateAbroadProgress: (state: TravelAbroadState, action: PayloadAction<{ title: string; id: number }>) => {
      //
      const { title, id } = action.payload
      //
      const updated = state.progress.map((item) => (item.id === id ? { ...item, title } : item))
      //
      const changeStatus = updated.map((item) => {
        //
        if (item.id === id) {
          // If the item's id matches the provided id, update accordingly
          return {
            ...item,
            active: true,
            passed: true,
          }
        } else if (item.id && item.id < id) {
          // If the item's id is less than the provided id, mark as passed
          return {
            ...item,
            active: false,
            passed: true,
          }
        } else {
          // If the item's id is greater than the provided id, mark as not passed or active
          return {
            ...item,
            active: false,
            passed: false,
          }
        }
      })
      //
      state.progress = changeStatus
      //
    },
    setAbroadVisibleStep: (state: TravelAbroadState, action: PayloadAction<{ id: number; isVisible: boolean }>) => {
      //
      const { id, isVisible } = action.payload
      //
      const updatedProgress = state.progress?.map((item) => (item.id === id ? { ...item, isVisible } : item))
      //
      state.progress = updatedProgress
    },
    //
    setProgress: (state: TravelAbroadState, { payload: progress }: PayloadAction<ProgressType>) => {
      //
      // Check if the object with the specified id already exists in the array
      const existingItem = state.progress.find((item) => item.url === progress.url)

      if (existingItem) {
        // Update the 'value' property of the existing object
        const updatedData = state.progress.map((item) =>
          item.url === progress.url ? { ...item, active: progress.active, passed: progress.passed } : item,
        )
        //
        const changeStatus = updatedData.map((upItem) => ({
          ...upItem,
          active: upItem.active,
          passed: progress.passed,
        }))
        //
        // Set the updated array back into the state
        state.progress = changeStatus
        //
      } else {
        // Add a new object to the array
        const newItem: ProgressType = {
          title: progress.title,
          url: progress.url,
          active: progress.active,
          passed: progress.passed,
        }
        //
        const updatedData = [...state.progress, newItem]
        //
        const changeStatus = updatedData.map((upItem) => ({
          ...upItem,
          active: upItem.url === progress.url,
          passed: false,
        }))
        //
        state.progress = changeStatus
      }
    },
    //
    setProductCategory: (
      state: TravelAbroadState,
      { payload: productCategory }: PayloadAction<TravelAbroadState['filter']['productCategory']>,
    ) => {
      state.filter = {
        ...state.filter,
        productCategory,
      }
    },
    //
    setUniquedId: (
      state: TravelAbroadState,
      { payload: uniqueId }: PayloadAction<TravelAbroadState['filter']['uniqueId']>,
    ) => {
      state.filter = {
        ...state.filter,
        uniqueId,
      }
    },
    //
    setOfferTotalPrice: (
      state: TravelAbroadState,
      { payload: offerTotalPrice }: PayloadAction<TravelAbroadState['filter']['offerTotalPrice']>,
    ) => {
      state.filter = {
        ...state.filter,
        offerTotalPrice,
      }
    },
    //
    setSingleOrMulti: (
      state: TravelAbroadState,
      { payload: singleOrMulti }: PayloadAction<TravelAbroadState['filter']['singleOrMulti']>,
    ) => {
      state.filter = {
        ...state.filter,
        singleOrMulti,
      }
    },
    //
    setHeaderPassedCount: (
      state: TravelAbroadState,
      { payload: passedCount }: PayloadAction<TravelAbroadState['screenHeader']['passedCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        passedCount,
      }
    },
    //
    setHeaderTotalCount: (
      state: TravelAbroadState,
      { payload: totalCount }: PayloadAction<TravelAbroadState['screenHeader']['totalCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        totalCount,
      }
    },
    //
    setSingleDates: (
      state: TravelAbroadState,
      { payload: singleDates }: PayloadAction<TravelAbroadState['filter']['singleDates']>,
    ) => {
      state.filter = {
        ...state.filter,
        singleDates,
      }
    },
    //
    setRegion: (
      state: TravelAbroadState,
      { payload: region }: PayloadAction<TravelAbroadState['filter']['region']>,
    ) => {
      state.filter = {
        ...state.filter,
        region,
      }
    },
    //
    setBetterOffer: (
      state: TravelAbroadState,
      { payload: betterOffer }: PayloadAction<TravelAbroadState['filter']['betterOffer']>,
    ) => {
      state.filter = {
        ...state.filter,
        betterOffer,
      }
    },
    //
    setMultiDates: (
      state: TravelAbroadState,
      { payload: multiDates }: PayloadAction<TravelAbroadState['filter']['multiDates']>,
    ) => {
      state.filter = {
        ...state.filter,
        multiDates,
      }
    },
    //
    setMultiDays: (
      state: TravelAbroadState,
      { payload: multiDays }: PayloadAction<TravelAbroadState['filter']['multiDays']>,
    ) => {
      state.filter = {
        ...state.filter,
        multiDays,
      }
    },
    //
    setCurrency: (
      state: TravelAbroadState,
      { payload: currency }: PayloadAction<TravelAbroadState['filter']['currency']>,
    ) => {
      state.filter = {
        ...state.filter,
        currency,
      }
    },
    //
    setLimit: (state: TravelAbroadState, { payload: limit }: PayloadAction<TravelAbroadState['filter']['limit']>) => {
      state.filter = {
        ...state.filter,
        limit,
      }
    },
    //
    setOffer: (state: TravelAbroadState, { payload: offer }: PayloadAction<TravelAbroadState['filter']['offer']>) => {
      state.filter = {
        ...state.filter,
        offer,
      }
    },
    //
    setFranchise: (
      state: TravelAbroadState,
      { payload: franchise }: PayloadAction<TravelAbroadState['filter']['franchise']>,
    ) => {
      state.filter = {
        ...state.filter,
        franchise,
      }
    },
    //
    addEmptyTraveler: (
      state: TravelAbroadState,
      { payload: travelersDate }: PayloadAction<TravelAbroadState['filter']['travelersDate']>,
    ) => {
      state.filter = {
        ...state.filter,
        travelersDate: [
          ...(state.filter.travelersDate || []),
          {
            age: 0,
            date: null,
            dateFormat: null,
            month: null,
          },
        ],
      }
    },
    //
    updateTravelerAtIndex: (
      state: TravelAbroadState,
      { payload }: PayloadAction<{ travelerIndex: number; newValue: TravelersDate }>,
    ) => {
      state.filter = {
        ...state.filter,
        travelersDate: (state.filter.travelersDate || []).map((item, index) => {
          return index === payload.travelerIndex ? payload.newValue : item
        }),
      }
    },
    //
    removeTravelerAtIndex: (state: TravelAbroadState, { payload: travelerIndex }: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        travelersDate: (state.filter.travelersDate || []).filter((item, index) => index !== travelerIndex),
      }
    },
    //
    setChooseMethod: (
      state: TravelAbroadState,
      { payload: chooseMethod }: PayloadAction<TravelAbroadState['filter']['chooseMethod']>,
    ) => {
      state.filter = {
        ...state.filter,
        chooseMethod,
      }
    },
    // 
    setPromoCodeValue: (
      state: TravelAbroadState,
      { payload: promoCodeValue }: PayloadAction<TravelAbroadState['filter']['promoCodeValue']>,
    ) => {
      state.filter = {
        ...state.filter,
        promoCodeValue,
      }
    },
    //
    setPromoCode: (state: TravelAbroadState, { payload: promoCode }: PayloadAction<TravelAbroadState['filter']['promoCode']>) => {
      state.filter = {
        ...state.filter,
        promoCode,
      }
    },
    //    
    setDiscountUsedSuccessfully: (
      state: TravelAbroadState,
      { payload: discountUsedSuccesfully }: PayloadAction<TravelAbroadState['filter']['discountUsedSuccesfully']>,
    ) => {
      state.filter = {
        ...state.filter,
        discountUsedSuccesfully,
      }
    },
    // 
    setPromoCodeUsedSuccessfully: (
      state: TravelAbroadState,
      { payload: promoCodeUsedSuccessfully }: PayloadAction<TravelAbroadState['filter']['promoCodeUsedSuccessfully']>,
    ) => {
      state.filter = {
        ...state.filter,
        promoCodeUsedSuccessfully,
      }
    },
    // 
    setCompanyOffers: (state: TravelAbroadState, { payload: companyOffers }: PayloadAction<AbroadOffer[]>) => {
      state.filter = {
        ...state.filter,
        companyOffers,
      }
    },
    //
    setCompanyId: (
      state: TravelAbroadState,
      { payload: selectedCompanies }: PayloadAction<TravelAbroadState['filter']['selectedCompanies']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedCompanies,
      }
    },
    //
    setLeftCompanyIds: (
      state: TravelAbroadState,
      { payload: leftCompanyIds }: PayloadAction<TravelAbroadState['filter']['leftCompanyIds']>,
    ) => {
      state.filter = {
        ...state.filter,
        leftCompanyIds,
      }
    },
    //
    setAddon: (state: TravelAbroadState, { payload: { addon, count } }: PayloadAction<AttachedAddonType>) => {
      //
      if (state.filter.attachedAddons && state.filter.attachedAddons.length === 0) {
        state.filter = {
          ...state.filter,
          attachedAddons: [{ addon: addon, count: count }],
        }
      }
      //
      if (state.filter.attachedAddons && state.filter.attachedAddons.find((x) => x.addon.addonId !== addon.addonId)) {
        state.filter = {
          ...state.filter,
          attachedAddons: [...(state.filter.attachedAddons || []), { addon: addon, count: count }],
        }
      }
    },
    //
    setGroupAddon: (
      state: TravelAbroadState,
      { payload: { addon, count } }: PayloadAction<GroupedAttachedAddonType>,
    ) => {
      //
      if (state.filter.groupedAttachedAddons && state.filter.groupedAttachedAddons.length === 0) {
        state.filter = {
          ...state.filter,
          groupedAttachedAddons: [{ addon: addon, count: count }],
        }
      }
      //
      if (
        state.filter.groupedAttachedAddons &&
        state.filter.groupedAttachedAddons.find((x) => x.addon.id !== addon.id)
      ) {
        state.filter = {
          ...state.filter,
          groupedAttachedAddons: [...(state.filter.groupedAttachedAddons || []), { addon: addon, count: count }],
        }
      }
    },
    //
    updateAddon: (state: TravelAbroadState, action: PayloadAction<{ addonId: number; count: number }>) => {
      const { addonId, count } = action.payload
      //
      const updatedObjects = state.filter.attachedAddons?.map((obj) =>
        obj.addon.addonId === addonId ? { ...obj, count: count } : obj,
      )
      //
      state.filter.attachedAddons = updatedObjects
    },
    //
    updateGroupAddon: (state: TravelAbroadState, action: PayloadAction<{ groupId: number; count: number }>) => {
      const { groupId, count } = action.payload
      //
      const updatedObjects = state.filter.groupedAttachedAddons?.map((obj) =>
        obj.addon.id === groupId ? { ...obj, count: count } : obj,
      )
      //
      state.filter.groupedAttachedAddons = updatedObjects
    },
    //
    clearAddon: (
      state: TravelAbroadState,
      { payload: attachedAddons }: PayloadAction<TravelAbroadState['filter']['attachedAddons']>,
    ) => {
      state.filter.attachedAddons = attachedAddons
    },
    //
    clearGroupAddon: (
      state: TravelAbroadState,
      { payload: groupedAttachedAddons }: PayloadAction<TravelAbroadState['filter']['groupedAttachedAddons']>,
    ) => {
      state.filter.groupedAttachedAddons = groupedAttachedAddons
    },
    //
    removeAddon: (state: TravelAbroadState, { payload: addonId }: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        attachedAddons: state.filter.attachedAddons?.filter((x) => x.addon.addonId !== addonId),
      }
    },
    //
    removeGroupAddon: (state: TravelAbroadState, { payload: groupId }: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        groupedAttachedAddons: state.filter.groupedAttachedAddons?.filter((x) => x.addon.id !== groupId),
      }
    },
    //
    //
    // END filter actions
    //
    //
    //
    //
    // BEGIN checkout actions
    setForWho: (
      state: TravelAbroadState,
      { payload: forWho }: PayloadAction<TravelAbroadState['checkout']['forWho']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        forWho,
      }
    },
    //
    removeTravelerObjAtIndex: (state: TravelAbroadState, { payload: travelerIndex }: PayloadAction<number>) => {
      //
      state.checkout = {
        ...state.checkout,
        travelers: (state.checkout.travelers || []).filter((item, index) => index !== travelerIndex),
      }
    },
    //
    setTravelers: (
      state: TravelAbroadState,
      { payload: travelers }: PayloadAction<TravelAbroadState['checkout']['travelers']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        travelers: travelers,
      }
    },
    //
    setInsurerInfo: (
      state: TravelAbroadState,
      action: PayloadAction<{ fieldName: keyof TravelAbroadState['checkout']['insurer']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insurer[fieldName] = fieldValue
    },
    //
    setInsurerObj: (
      state: TravelAbroadState,
      { payload: insurer }: PayloadAction<TravelAbroadState['checkout']['insurer']>,
    ) => {
      state.checkout.insurer = insurer
    },
    //
    setInsurerTranslationInfo: (
      state: TravelAbroadState,
      action: PayloadAction<{
        fieldName: keyof { firstName: string; lastName: string }
        language: EnabledLanguage
        fieldValue: any
      }>,
    ) => {
      const { fieldName, fieldValue, language } = action.payload
      state.checkout.insurer = {
        ...state.checkout.insurer,
        translations: {
          ...state.checkout.insurer.translations,
          [language]: {
            ...state.checkout.insurer.translations?.[language],
            [fieldName]: fieldValue,
          },
        },
      }
    },
    //
    setOrderId: (
      state: TravelAbroadState,
      { payload: orderId }: PayloadAction<TravelAbroadState['checkout']['orderId']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        orderId,
      }
    },
    //
    setTotalPrice: (
      state: TravelAbroadState,
      { payload: totalPrice }: PayloadAction<TravelAbroadState['checkout']['totalPrice']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        totalPrice,
      }
    },
    //
    // END checkout actions
    //
  },
})

export const {
  // BEGIN filter actions
  clearAbroadStore,
  setAbroadVisibleStep,
  setUpdateAbroadProgress,
  setProductCategory,
  setUniquedId,
  setSingleOrMulti,
  setHeaderPassedCount,
  setHeaderTotalCount,
  setSingleDates,
  setRegion,
  setMultiDates,
  setMultiDays,
  setCurrency,
  setLimit,
  setFranchise,
  setOffer,
  setCompanyOffers,
  addEmptyTraveler,
  updateTravelerAtIndex,
  removeTravelerAtIndex,
  setChooseMethod,
  setCompanyId,
  setLeftCompanyIds,
  setAddon,
  updateAddon,
  clearAddon,
  removeAddon,
  setGroupAddon,
  clearGroupAddon,
  updateGroupAddon,
  removeGroupAddon,
  setProgress,
  setBetterOffer,
  setOfferTotalPrice,
  setPromoCode,
  setPromoCodeValue,
  setDiscountUsedSuccessfully,
  setPromoCodeUsedSuccessfully,
  // END filter actions
  //
  // BEGIN checjout actions
  setForWho,
  setInsurerInfo,
  setInsurerTranslationInfo,
  setInsurerObj,
  setOrderId,
  setTotalPrice,
  removeTravelerObjAtIndex,
  setTravelers,
  // END checjout actions
} = slice.actions

export default slice.reducer
