import { createSlice, PayloadAction } from '@reduxjs/toolkit'
//
//
export type PersonalType = {
  firstName?: string
  lastName?: string
  firstNameGe?: string
  lastNameGe?: string
  personalNumber?: string
  passportNumber?: string
  phone?: string
  email?: string
  citizenship?: string
  gender?: string
  birthday?: string
  sgsVerificationSended?: boolean
  sgsVerification?: boolean
  password?: string
  confirmPassword?: string
  terms?: boolean
}
//
export type NewUserState = {
  personal: PersonalType
}
//
//
const realInitialState: NewUserState = {
  personal: {
    firstName: '',
    lastName: '',
    firstNameGe: '',
    lastNameGe: '',
    personalNumber: '',
    passportNumber: '',
    phone: '',
    email: '',
    citizenship: 'GE',
    gender: '',
    birthday: '',
    sgsVerificationSended: false,
    sgsVerification: false,
    password: '',
    confirmPassword: '',
    terms: false,
  },
}
//
//
const initialState = realInitialState
//
//
const slice = createSlice({
  name: 'newUserSlice',
  initialState,
  reducers: {
    //
    // BEGIN filter actions
    clearNewUserStore: (state: NewUserState, actionPayload: PayloadAction<undefined>) => {
      return {
        ...initialState,
      }
    },
    //
    setNewUserField: (
      state: NewUserState,
      action: PayloadAction<{ fieldName: keyof NewUserState['personal']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.personal[fieldName] = fieldValue
    },
    //
    setNewUserObj: (state: NewUserState, { payload: personal }: PayloadAction<NewUserState['personal']>) => {
      state.personal = personal
    },
    //
  },
})

export const { clearNewUserStore, setNewUserField, setNewUserObj } = slice.actions

export default slice.reducer
