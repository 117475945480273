import { EnabledLanguage, isSupportedLanguage } from '../domain/types'

export const getLang = () => {
  const lang = localStorage.getItem('i18nextLng')
  // check if restored lang exists and is a supported language
  if (isSupportedLanguage(lang)) {
    return lang as EnabledLanguage
  } else {
    return null
  }
}

export const setLang = (lang: EnabledLanguage) => {
  localStorage.setItem('i18nextLng', lang)
}

// TODO
export const detectUserLanguage = (): EnabledLanguage => {
  // todo consider /en /de locales in URL
  return getLang() && isSupportedLanguage(getLang())
    ? (getLang() as EnabledLanguage)
    : navigator.language === 'ka_GE'
    ? ('ge' as EnabledLanguage)
    : navigator.language === 'en_US'
    ? ('en' as EnabledLanguage)
    : navigator.language === 'ru_RU'
    ? ('ru' as EnabledLanguage)
    : ('en' as EnabledLanguage)
}
