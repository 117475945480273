import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DiscountedPaymentList,
  EnabledLanguage,
  PetBestOffer,
  PetInsurer,
  ProgressBarType,
  TplPaymentMethod,
} from '../domain/types'
//
//
type PetInfo = {
  pet: string
  name: string
  age: number | null
  month: number | null
  color: string
  breed: string
  breedOther: string
  gender: string
}
//#############################
export type PetFlowFilter = {
  dates: {
    start: string | null
    end: string | null
  }
  petInfo: Partial<PetInfo>
  seletedPaymentMethod: {
    id: number | null
    type: string
  }
  breedId: number | null
  petCategoryId: number | null
  paymentScheduleMethods: TplPaymentMethod[]
  paymentIds: number[]
  promoCode: string
  promoCodeValue: string
  discountUsedSuccessfully: boolean
  promoCodeUsedSuccessfully: boolean
  payment:
    | {
        firstPaymentAmount: number
        monthlyPaymentAmount: number
        totalPaymentAmount: number
      }
    | undefined
  discountedPayment: DiscountedPaymentList | undefined
  offer?: PetBestOffer
  offerTotalPrice?: string
}
//
export type PetCheckoutFlow = {
  insurer: Partial<PetInsurer>
  orderId: number
  totalPrice: number
  subscriptionAgree: boolean
}
//
export type PetState = {
  filter: PetFlowFilter
  checkout: PetCheckoutFlow
  screenHeader: {
    totalCount: number
    passedCount: number
  }
  progress: ProgressBarType[]
}
//
//
//
//
const realInitialState: PetState = {
  filter: {
    petInfo: {
      pet: '',
      name: '',
      age: null,
      month: null,
      color: '',
      breed: '',
      breedOther: '',
      gender: '',
    },
    dates: {
      start: null,
      end: null,
    },
    paymentScheduleMethods: [],
    seletedPaymentMethod: {
      id: null,
      type: 'MONTHLY',
    },
    breedId: null,
    petCategoryId: null,
    paymentIds: [],
    promoCode: '',
    promoCodeValue: '',
    discountUsedSuccessfully: false,
    promoCodeUsedSuccessfully: false,
    payment: {
      firstPaymentAmount: 0,
      monthlyPaymentAmount: 0,
      totalPaymentAmount: 0,
    },
    discountedPayment: undefined,
    offer: undefined,
    offerTotalPrice: '',
  },
  //
  checkout: {
    insurer: {
      cityzenship: 'GE',
    },
    orderId: 0,
    totalPrice: 0,
    subscriptionAgree: false,
  },
  screenHeader: {
    totalCount: 7,
    passedCount: 1,
  },
  progress: [
    {
      id: 1,
      title: '',
      url: '/choose-pet',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 2,
      title: '',
      url: '/pet-info',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 3,
      title: '',
      url: '/pet-start-date',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 5,
      title: '',
      url: '/pet-offer-method',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 6,
      title: '',
      url: '/pet-offers',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 7,
      title: '',
      url: '/pet-offer',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 8,
      title: '',
      url: '/pet-insurer',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 9,
      title: '',
      url: '/pet-check-information',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 10,
      title: '',
      url: '/pet-policy-registration',
      active: false,
      passed: false,
      isVisible: true,
    },
  ],
}
//
//
//
//
const initialState = realInitialState
//
//
const slice = createSlice({
  name: 'petSlice',
  initialState,
  reducers: {
    //
    // BEGIN filter actions
    clearPetStore: (state: PetState, actionPayload: PayloadAction<undefined>) => {
      return {
        ...initialState,
      }
    },
    //
    setUpdatepetProgress: (state: PetState, action: PayloadAction<{ title: string; id: number }>) => {
      //
      const { title, id } = action.payload
      //
      const updated = state.progress.map((item) => (item.id === id ? { ...item, title } : item))
      //
      const changeStatus = updated.map((item) => {
        //
        if (item.id === id) {
          // If the item's id matches the provided id, update accordingly
          return {
            ...item,
            active: true,
            passed: true,
          }
        } else if (item.id && item.id < id) {
          // If the item's id is less than the provided id, mark as passed
          return {
            ...item,
            active: false,
            passed: true,
          }
        } else {
          // If the item's id is greater than the provided id, mark as not passed or active
          return {
            ...item,
            active: false,
            passed: false,
          }
        }
      })
      //
      state.progress = changeStatus
      //
    },
    //
    //
    setPetVisibleStep: (state: PetState, action: PayloadAction<{ id: number; isVisible: boolean }>) => {
      //
      const { id, isVisible } = action.payload
      //
      const updatedProgress = state.progress?.map((item) => (item.id === id ? { ...item, isVisible } : item))
      //
      state.progress = updatedProgress
      //
    },
    //
    setPetHeaderPassedCount: (
      state: PetState,
      { payload: passedCount }: PayloadAction<PetState['screenHeader']['passedCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        passedCount,
      }
    },
    //
    setPetHeaderTotalCount: (
      state: PetState,
      { payload: totalCount }: PayloadAction<PetState['screenHeader']['totalCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        totalCount,
      }
    },
    //
    setPetFields: (
      state: PetState,
      action: PayloadAction<{ fieldName: keyof PetState['filter']['petInfo']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.filter.petInfo[fieldName] = fieldValue
    },
    //
    setPetDates: (state: PetState, { payload: dates }: PayloadAction<PetState['filter']['dates']>) => {
      state.filter.dates = dates
    },
    //
    setInsurerInfo: (
      state: PetState,
      action: PayloadAction<{ fieldName: keyof PetState['checkout']['insurer']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insurer[fieldName] = fieldValue
    },
    //
    setInsurerObj: (state: PetState, { payload: insurer }: PayloadAction<PetState['checkout']['insurer']>) => {
      state.checkout.insurer = insurer
    },
    //
    setInsurerTranslationInfo: (
      state: PetState,
      action: PayloadAction<{
        fieldName: keyof { firstName: string; lastName: string }
        language: EnabledLanguage
        fieldValue: any
      }>,
    ) => {
      const { fieldName, fieldValue, language } = action.payload
      state.checkout.insurer = {
        ...state.checkout.insurer,
        translations: {
          ...state.checkout.insurer.translations,
          [language]: {
            ...state.checkout.insurer.translations?.[language],
            [fieldName]: fieldValue,
          },
        },
      }
    },
    //
    setPetPaymentSchedule: (
      state: PetState,
      { payload: seletedPaymentMethod }: PayloadAction<PetState['filter']['seletedPaymentMethod']>,
    ) => {
      state.filter = {
        ...state.filter,
        seletedPaymentMethod,
      }
    },
    //
    setPaymentScheduleMethods: (
      state: PetState,
      { payload: paymentScheduleMethods }: PayloadAction<PetState['filter']['paymentScheduleMethods']>,
    ) => {
      state.filter = {
        ...state.filter,
        paymentScheduleMethods,
      }
    },
    //
    setBreedId: (state: PetState, { payload: breedId }: PayloadAction<PetState['filter']['breedId']>) => {
      state.filter = {
        ...state.filter,
        breedId,
      }
    },
    //
    setPetCategoryId: (
      state: PetState,
      { payload: petCategoryId }: PayloadAction<PetState['filter']['petCategoryId']>,
    ) => {
      state.filter = {
        ...state.filter,
        petCategoryId,
      }
    },
    //
    setPaymentIds: (state: PetState, { payload: paymentIds }: PayloadAction<PetState['filter']['paymentIds']>) => {
      state.filter = {
        ...state.filter,
        paymentIds,
      }
    },
    //
    setOfferTotalPrice: (
      state: PetState,
      { payload: offerTotalPrice }: PayloadAction<PetState['filter']['offerTotalPrice']>,
    ) => {
      state.filter = {
        ...state.filter,
        offerTotalPrice,
      }
    },
    //
    setOffer: (state: PetState, { payload: offer }: PayloadAction<PetState['filter']['offer']>) => {
      state.filter = {
        ...state.filter,
        offer,
      }
    },
    //
    setPromoCodeValue: (
      state: PetState,
      { payload: promoCodeValue }: PayloadAction<PetState['filter']['promoCodeValue']>,
    ) => {
      state.filter = {
        ...state.filter,
        promoCodeValue,
      }
    },
    //
    setPromoCode: (state: PetState, { payload: promoCode }: PayloadAction<PetState['filter']['promoCode']>) => {
      state.filter = {
        ...state.filter,
        promoCode,
      }
    },
    //
    setOrderId: (state: PetState, { payload: orderId }: PayloadAction<PetState['checkout']['orderId']>) => {
      state.checkout = {
        ...state.checkout,
        orderId,
      }
    },
    //
    setDiscountUsedSuccessfully: (
      state: PetState,
      { payload: discountUsedSuccessfully }: PayloadAction<PetState['filter']['discountUsedSuccessfully']>,
    ) => {
      state.filter = {
        ...state.filter,
        discountUsedSuccessfully,
      }
    },
    //
    setPromoCodeUsedSuccessfully: (
      state: PetState,
      { payload: promoCodeUsedSuccessfully }: PayloadAction<PetState['filter']['promoCodeUsedSuccessfully']>,
    ) => {
      state.filter = {
        ...state.filter,
        promoCodeUsedSuccessfully,
      }
    },
    //
    setPayment: (state: PetState, { payload: payment }: PayloadAction<PetState['filter']['payment']>) => {
      state.filter = {
        ...state.filter,
        payment,
      }
    },
    //
    setDiscountedPayment: (
      state: PetState,
      { payload: discountedPayment }: PayloadAction<PetState['filter']['discountedPayment']>,
    ) => {
      state.filter = {
        ...state.filter,
        discountedPayment,
      }
    },
    //
    setTotalPrice: (state: PetState, { payload: totalPrice }: PayloadAction<PetState['checkout']['totalPrice']>) => {
      state.checkout = {
        ...state.checkout,
        totalPrice,
      }
    },
    //
    setSubscriptionAgree: (
      state: PetState,
      { payload: subscriptionAgree }: PayloadAction<PetState['checkout']['subscriptionAgree']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        subscriptionAgree,
      }
    },
    //
  },
})

export const {
  clearPetStore,
  setUpdatepetProgress,
  setPetVisibleStep,
  setPetHeaderPassedCount,
  setPetHeaderTotalCount,
  setPetFields,
  setPetDates,
  setInsurerInfo,
  setInsurerTranslationInfo,
  setInsurerObj,
  setPetPaymentSchedule,
  setBreedId,
  setPetCategoryId,
  setPaymentScheduleMethods,
  setPaymentIds,
  setOffer,
  setOfferTotalPrice,
  setTotalPrice,
  setOrderId,
  setSubscriptionAgree,
  setPayment,
  setDiscountUsedSuccessfully,
  setPromoCodeUsedSuccessfully,
  setDiscountedPayment,
  setPromoCode,
  setPromoCodeValue,
} = slice.actions

export default slice.reducer
