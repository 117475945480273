import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DiscountedPaymentList,
  EnabledLanguage,
  PackageServices,
  ProgressBarType,
  TplCarInfoType,
  TplCompareCompany,
  TplCompareResponse,
  TplForMePersonalInfo,
  TplOfferResponse,
  TplPaymentMethod,
  TranslationsField,
} from '../domain/types'
//
//
type CurrenciesWithLimits = {
  currency: string
  limits: number[]
}

type SelectedCompaniesType = {
  id: number
  currency: number
  limit: number
}

type IcomeCompanies = {
  id: number
  brandName: TranslationsField<{
    title: string
  }>
  cover: string
  currenciesWithLimits: CurrenciesWithLimits[]
}
//
//
export type TplFlowFilter = {
  productCategory: string
  dates: {
    start: Date | string | null
    end: Date | string | null
    days: number
  }
  currency: string
  limit: number | null
  paymentScheduleMethods: TplPaymentMethod[]
  fistPageModalIsOpen: boolean
  seletedPaymentMethod: {
    id: number | null
    type: string
  }
  payment: {
    firstPaymentAmount: number
    monthlyPaymentAmount: number
    totalPaymentAmount: number
  }
  discountedPayment: DiscountedPaymentList
  chooseMethod: string
  promoCode: string
  promoCodeValue: string
  discountUsedSuccessfully: boolean
  promoCodeUsedSuccessfully: boolean
  currencies: string[]
  incomeServices: PackageServices[]
  incomeCompanies: IcomeCompanies[]
  selectedCompanies: SelectedCompaniesType[] | []
  selectedServices: number[] | []
  leftCompanyIds: number[] | []
  offer?: TplOfferResponse
  offerFromUser?: TplCompareCompany | undefined
  uniqueId: string
  companyOffers: TplOfferResponse[] | TplCompareResponse
  offerTotalPrice?: string
}
//
//
export type TplCheckoutFlow = {
  forWho: string
  insurer: Partial<TplForMePersonalInfo>
  insured: Partial<TplForMePersonalInfo>
  checkMyCar: boolean
  insuranceCar: TplCarInfoType
  carCountdown: {
    minute: number
    second: number
  }
  orderId: number
  totalPrice: number
  subscriptionAgree: boolean
}
//
//
export type TplState = {
  filter: Partial<TplFlowFilter>
  checkout: TplCheckoutFlow
  screenHeader: {
    totalCount: number
    passedCount: number
  }
  progress: ProgressBarType[]
}
//
//
//
//
const realInitialState: TplState = {
  filter: {
    productCategory: 'tpl',
    dates: {
      start: null,
      end: null,
      days: 0,
    },
    currency: '',
    limit: null,
    selectedServices: [],
    selectedCompanies: [],
    chooseMethod: '',
    promoCode: '',
    promoCodeValue: '',
    discountUsedSuccessfully: false,
    promoCodeUsedSuccessfully: false,
    currencies: [],
    incomeServices: [],
    incomeCompanies: [],
    leftCompanyIds: [],
    offerFromUser: undefined,
    paymentScheduleMethods: [],
    fistPageModalIsOpen: true,
    seletedPaymentMethod: {
      id: null,
      type: 'ONE-TIME',
    },
    offer: undefined,
    companyOffers: [],
    offerTotalPrice: '',
    payment: {
      firstPaymentAmount: 0,
      monthlyPaymentAmount: 0,
      totalPaymentAmount: 0
    },
    discountedPayment: undefined
  },
  //
  checkout: {
    forWho: '',
    insurer: {
      cityzenship: 'GE',
    },
    insured: {
      cityzenship: 'GE',
    },
    checkMyCar: false,
    insuranceCar: {
      registrationNumber: '',
      techPassport: '',
      vinCode: '',
      carType: '',
      brand: '',
      model: '',
      yearOfManufacture: '',
      engineCapacity: '',
    },
    carCountdown: {
      minute: 0, // default 5 min
      second: 0,
    },
    orderId: 0,
    totalPrice: 0,
    subscriptionAgree: false,
  },
  screenHeader: {
    totalCount: 10,
    passedCount: 1,
  },
  progress: [
    {
      id: 2,
      title: '',
      url: '/tpl-choose-days',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 3,
      title: '',
      url: '/tpl-which-currency',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 4,
      title: '',
      url: '/tpl-extra-services',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 5,
      title: '',
      url: '/tpl-which-method',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 6,
      title: '',
      url: '/tpl-offers',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 7,
      title: '',
      url: '/tpl-offer',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 8,
      title: '',
      url: '/tpl-insurance-for',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 9,
      title: '',
      url: '/tpl-for-me',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 10,
      title: '',
      url: '/tpl-for-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 11,
      title: '',
      url: '/tpl-insurer',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 12,
      title: '',
      url: '/tpl-car-info',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 13,
      title: '',
      url: '/tpl-check-information',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 14,
      title: '',
      url: '/tpl-policy-registration',
      active: false,
      passed: false,
      isVisible: true,
    },
  ],
}
//
//
//
//
const initialState = realInitialState
//
//
const slice = createSlice({
  name: 'tplSlice',
  initialState,
  reducers: {
    //
    // BEGIN filter actions
    clearTplStore: (state: TplState, actionPayload: PayloadAction<undefined>) => {
      return {
        ...initialState,
      }
    },
    //
    setUpdateTplProgress: (state: TplState, action: PayloadAction<{ title: string; id: number }>) => {
      //
      const { title, id } = action.payload
      //
      const updated = state.progress.map((item) => (item.id === id ? { ...item, title } : item))
      //
      const changeStatus = updated.map((item) => {
        //
        if (item.id === id) {
          // If the item's id matches the provided id, update accordingly
          return {
            ...item,
            active: true,
            passed: true,
          }
        } else if (item.id && item.id < id) {
          // If the item's id is less than the provided id, mark as passed
          return {
            ...item,
            active: false,
            passed: true,
          }
        } else {
          // If the item's id is greater than the provided id, mark as not passed or active
          return {
            ...item,
            active: false,
            passed: false,
          }
        }
      })
      //
      state.progress = changeStatus
      //
    },
    //
    //
    setTplVisibleStep: (state: TplState, action: PayloadAction<{ id: number; isVisible: boolean }>) => {
      //
      const { id, isVisible } = action.payload
      //
      const updatedProgress = state.progress?.map((item) => (item.id === id ? { ...item, isVisible } : item))
      //
      state.progress = updatedProgress
      //
    },
    //
    //
    setProductCategory: (
      state: TplState,
      { payload: productCategory }: PayloadAction<TplState['filter']['productCategory']>,
    ) => {
      state.filter = {
        ...state.filter,
        productCategory,
      }
    },
    //
    setUniquedId: (state: TplState, { payload: uniqueId }: PayloadAction<TplState['filter']['uniqueId']>) => {
      state.filter = {
        ...state.filter,
        uniqueId,
      }
    },
    //
    setOfferFromUser: (
      state: TplState,
      { payload: offerFromUser }: PayloadAction<TplState['filter']['offerFromUser']>,
    ) => {
      state.filter = {
        ...state.filter,
        offerFromUser,
      }
    },
    //
    setOfferTotalPrice: (
      state: TplState,
      { payload: offerTotalPrice }: PayloadAction<TplState['filter']['offerTotalPrice']>,
    ) => {
      state.filter = {
        ...state.filter,
        offerTotalPrice,
      }
    },
    //
    setHeaderPassedCount: (
      state: TplState,
      { payload: passedCount }: PayloadAction<TplState['screenHeader']['passedCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        passedCount,
      }
    },
    //
    setHeaderTotalCount: (
      state: TplState,
      { payload: totalCount }: PayloadAction<TplState['screenHeader']['totalCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        totalCount,
      }
    },
    //
    setDates: (state: TplState, { payload: dates }: PayloadAction<TplState['filter']['dates']>) => {
      state.filter = {
        ...state.filter,
        dates,
      }
    },
    //
    setCurrency: (state: TplState, { payload: currency }: PayloadAction<TplState['filter']['currency']>) => {
      state.filter = {
        ...state.filter,
        currency,
      }
    },
    //
    setLimit: (state: TplState, { payload: limit }: PayloadAction<TplState['filter']['limit']>) => {
      state.filter = {
        ...state.filter,
        limit,
      }
    },
    //
    setOffer: (state: TplState, { payload: offer }: PayloadAction<TplState['filter']['offer']>) => {
      state.filter = {
        ...state.filter,
        offer,
      }
    },
    //
    setIncomeServices: (
      state: TplState,
      { payload: incomeServices }: PayloadAction<TplState['filter']['incomeServices']>,
    ) => {
      state.filter = {
        ...state.filter,
        incomeServices,
      }
    },
    //
    setCurrencies: (state: TplState, { payload: currencies }: PayloadAction<TplState['filter']['currencies']>) => {
      state.filter = {
        ...state.filter,
        currencies,
      }
    },
    //
    setIncomeCompanies: (
      state: TplState,
      { payload: incomeCompanies }: PayloadAction<TplState['filter']['incomeCompanies']>,
    ) => {
      state.filter = {
        ...state.filter,
        incomeCompanies,
      }
    },
    //
    setChooseMethod: (
      state: TplState,
      { payload: chooseMethod }: PayloadAction<TplState['filter']['chooseMethod']>,
    ) => {
      state.filter = {
        ...state.filter,
        chooseMethod,
      }
    },
    //
    setPromoCodeValue: (
      state: TplState,
      { payload: promoCodeValue }: PayloadAction<TplState['filter']['promoCodeValue']>,
    ) => {
      state.filter = {
        ...state.filter,
        promoCodeValue,
      }
    },
    //
    setPromoCode: (state: TplState, { payload: promoCode }: PayloadAction<TplState['filter']['promoCode']>) => {
      state.filter = {
        ...state.filter,
        promoCode,
      }
    },
    // 
    setDiscountUsedSuccessfully: (
      state: TplState,
      { payload: discountUsedSuccessfully }: PayloadAction<TplState['filter']['discountUsedSuccessfully']>,
    ) => {
      state.filter = {
        ...state.filter,
        discountUsedSuccessfully,
      }
    },
    // 
    setPromoCodeUsedSuccessfully: (
      state: TplState,
      { payload: promoCodeUsedSuccessfully }: PayloadAction<TplState['filter']['promoCodeUsedSuccessfully']>,
    ) => {
      state.filter = {
        ...state.filter,
        promoCodeUsedSuccessfully,
      }
    },
    //
    setCompanyOffers: (
      state: TplState,
      { payload: companyOffers }: PayloadAction<TplOfferResponse[] | TplCompareResponse>,
    ) => {
      state.filter = {
        ...state.filter,
        companyOffers,
      }
    },
    //
    setSelectedCompanies: (
      state: TplState,
      { payload: selectedCompanies }: PayloadAction<TplState['filter']['selectedCompanies']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedCompanies,
      }
    },
    //
    setSelectedServices: (
      state: TplState,
      { payload: selectedServices }: PayloadAction<TplState['filter']['selectedServices']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedServices,
      }
    },
    //
    setCheckMyCar: (state: TplState, { payload: checkMyCar }: PayloadAction<TplState['checkout']['checkMyCar']>) => {
      state.checkout = {
        ...state.checkout,
        checkMyCar,
      }
    },
    //
    setLeftCompanyIds: (
      state: TplState,
      { payload: leftCompanyIds }: PayloadAction<TplState['filter']['leftCompanyIds']>,
    ) => {
      state.filter = {
        ...state.filter,
        leftCompanyIds,
      }
    },
    //
    setPaymentScheduleMethods: (
      state: TplState,
      { payload: paymentScheduleMethods }: PayloadAction<TplState['filter']['paymentScheduleMethods']>,
    ) => {
      state.filter = {
        ...state.filter,
        paymentScheduleMethods,
      }
    },
    //
    setPaymentSchedule: (
      state: TplState,
      { payload: seletedPaymentMethod }: PayloadAction<TplState['filter']['seletedPaymentMethod']>,
    ) => {
      state.filter = {
        ...state.filter,
        seletedPaymentMethod,
      }
    },
    //
    setFirstPageModalIsOpen: (
      state: TplState,
      { payload: fistPageModalIsOpen }: PayloadAction<TplState['filter']['fistPageModalIsOpen']>,
    ) => {
      state.filter = {
        ...state.filter,
        fistPageModalIsOpen,
      }
    },
    //
    setPayment: (
      state: TplState,
      { payload: payment }: PayloadAction<TplState['filter']['payment']>,
    ) => {
      state.filter = {
        ...state.filter,
        payment,
      }
    },
    // 
    setDiscountedPayment: (state: TplState, { payload: discountedPayment }: PayloadAction<TplState['filter']['discountedPayment']>) => {
      state.filter = {
        ...state.filter,
        discountedPayment,
      }
    },
    //
    // END filter actions
    //
    //
    //
    //
    // BEGIN checkout actions
    setForWho: (state: TplState, { payload: forWho }: PayloadAction<TplState['checkout']['forWho']>) => {
      state.checkout = {
        ...state.checkout,
        forWho,
      }
    },
    //
    setInsured: (state: TplState, { payload: insured }: PayloadAction<TplState['checkout']['insured']>) => {
      state.checkout = {
        ...state.checkout,
        insured: insured,
      }
    },
    //
    setInsurerInfo: (
      state: TplState,
      action: PayloadAction<{ fieldName: keyof TplState['checkout']['insurer']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insurer[fieldName] = fieldValue
    },
    //
    setInsuredInfo: (
      state: TplState,
      action: PayloadAction<{ fieldName: keyof TplState['checkout']['insured']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insured[fieldName] = fieldValue
    },
    //
    setInsurerObj: (state: TplState, { payload: insurer }: PayloadAction<TplState['checkout']['insurer']>) => {
      state.checkout.insurer = insurer
    },
    //
    setInsuredObj: (state: TplState, { payload: insured }: PayloadAction<TplState['checkout']['insured']>) => {
      state.checkout.insured = insured
    },
    //
    setInsurerTranslationInfo: (
      state: TplState,
      action: PayloadAction<{
        fieldName: keyof { firstName: string; lastName: string }
        language: EnabledLanguage
        fieldValue: any
      }>,
    ) => {
      const { fieldName, fieldValue, language } = action.payload
      state.checkout.insurer = {
        ...state.checkout.insurer,
        translations: {
          ...state.checkout.insurer.translations,
          [language]: {
            ...state.checkout.insurer.translations?.[language],
            [fieldName]: fieldValue,
          },
        },
      }
    },
    //
    setInsuredTranslationInfo: (
      state: TplState,
      action: PayloadAction<{
        fieldName: keyof { firstName: string; lastName: string }
        language: EnabledLanguage
        fieldValue: any
      }>,
    ) => {
      const { fieldName, fieldValue, language } = action.payload
      state.checkout.insured = {
        ...state.checkout.insured,
        translations: {
          ...state.checkout.insured.translations,
          [language]: {
            ...state.checkout.insured.translations?.[language],
            [fieldName]: fieldValue,
          },
        },
      }
    },
    //
    setOrderId: (state: TplState, { payload: orderId }: PayloadAction<TplState['checkout']['orderId']>) => {
      state.checkout = {
        ...state.checkout,
        orderId,
      }
    },
    //
    setTotalPrice: (state: TplState, { payload: totalPrice }: PayloadAction<TplState['checkout']['totalPrice']>) => {
      state.checkout = {
        ...state.checkout,
        totalPrice,
      }
    },
    //
    setSubscriptionAgree: (
      state: TplState,
      { payload: subscriptionAgree }: PayloadAction<TplState['checkout']['subscriptionAgree']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        subscriptionAgree,
      }
    },
    //
    setCarFields: (
      state: TplState,
      action: PayloadAction<{ fieldName: keyof TplState['checkout']['insuranceCar']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insuranceCar[fieldName] = fieldValue
    },
    //
    setTplInsuranceCarInfo: (
      state: TplState,
      { payload: insuranceCar }: PayloadAction<TplState['checkout']['insuranceCar']>,
    ) => {
      state.checkout.insuranceCar = insuranceCar
    },
    //
    setUpdateCarCountdown: (
      state: TplState,
      { payload: carCountdown }: PayloadAction<TplState['checkout']['carCountdown']>,
    ) => {
      state.checkout.carCountdown = carCountdown
    },
    //
    clearTplCarInfo: (state: TplState, actionPayload: PayloadAction<undefined>) => {
      state.checkout.insuranceCar = initialState.checkout.insuranceCar
    },
    //
    //
    // END checkout actions
    //
  },
})

export const {
  // BEGIN filter actions
  clearTplStore,
  setProductCategory,
  setUniquedId,
  setHeaderPassedCount,
  setHeaderTotalCount,
  setDates,
  setCurrency,
  setLimit,
  setOffer,
  setCompanyOffers,
  setSelectedServices,
  setChooseMethod,
  setPromoCodeValue,
  setPromoCode,
  setDiscountUsedSuccessfully,
  setPromoCodeUsedSuccessfully,
  setSelectedCompanies,
  setCheckMyCar,
  setLeftCompanyIds,
  setOfferTotalPrice,
  setIncomeServices,
  setIncomeCompanies,
  setCurrencies,
  setPaymentSchedule,
  setPaymentScheduleMethods,
  setFirstPageModalIsOpen,
  setOfferFromUser,
  setTplVisibleStep,
  setUpdateTplProgress,
  setPayment,
  setDiscountedPayment,
  // END filter actions
  //
  // BEGIN checjout actions
  setForWho,
  setInsurerInfo,
  setInsurerTranslationInfo,
  setInsurerObj,
  setInsuredInfo,
  setInsuredObj,
  setTplInsuranceCarInfo,
  clearTplCarInfo,
  setInsuredTranslationInfo,
  setOrderId,
  setTotalPrice,
  setSubscriptionAgree,
  setInsured,
  setCarFields,
  setUpdateCarCountdown,
  // END checjout actions
} = slice.actions

export default slice.reducer
