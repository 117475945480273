const Colors = {
  Black: '#000000',
  Primary: '#FFA700',
  Sazerac: '#FFF6E5',
  Tundora: '#4A4A4A',
  Mercury: '#E5E5E5',
  SilverChalice: '#A2A2A2',
  Alto: '#D4D4D4',
  Alabaster: '#FCFCFC',
  Geyser: '#D4DADE',

  // Design colors
  primaryBlack: '#263238',
  secondaryBlack: '#394C55',
  primaryGrey: '#76838B',
  secondaryGrey: '#D3DADE',
  grey: '#ECF0F1',
  lightGrey: '#F3F6F6',
  backgroundGrey: '#FAFBFB',
  white: '#FFFFFF',
  primaryRed: '#FE5F55',
  secondaryRed: '#FD6E65',
  lightRed: '#FFEFED',
  primaryGreen: '#209684',
  secondaryGreen: '#E4F2F0',
  sunsetOrange: '#FD554B',
  rollingStone: '#78838A',
  java: '#1BD0AD',
  nevadaGrey: '#627680',
  hoverRed: '#ef5148',
  shadow: '#0000000a',
  adminLightSilverGrey: '#c6cace', // 50% lightened silver grey
  blackHaze: '#F9FAFA',
}

export default Colors
