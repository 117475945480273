import React, { lazy, useCallback, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter, Navigate } from 'react-router-dom'

import { LiveChatWidget } from '@livechat/widget-react'

import { useAppDispatch, useAppSelector } from './store'

import ReactGA from 'react-ga4'

import api from './api'

import { clearLoginData } from './store/authSlice'
import { InitialStateType, setLiveChatVisibility } from './store/liveChatSlice'

import { DayjsLocalManager } from './screens/hooks/DayjsLocalManager'
import { AppState, setIsProduction } from './store/appSlice'
import i18n from './i18n'
import { useTranslation } from 'react-i18next'
//
const LoggedUserLayout = lazy(() => import('./screens/logged_user/layout/LoggedUserLayout'))

const Loader = lazy(() => import('./components/Loader'))
const Layout = lazy(() => import('./screens/nav/Layout'))
const Category = lazy(() => import('./screens/website/Category'))
const Landing = lazy(() => import('./screens/website/Landing'))
const Login = lazy(() => import('../src/screens/auth/login/Login'))
const PayFailureScreen = lazy(() => import('./screens/website/PayFailureScreen'))
const PaySuccessful = lazy(() => import('./screens/website/PaySuccessful'))
const ResetPasswordStepOne = lazy(() => import('./screens/auth/reset-password/ResetPasswordStepOne'))
const ResetPasswordStepTwo = lazy(() => import('./screens/auth/reset-password/ResetPasswordStepTwo'))
const SignUpPersonalInfo = lazy(() => import('./screens/auth/sign-up/SignUpPersonalInfo'))
const SignUpCreatePassword = lazy(() => import('./screens/auth/sign-up/SignUpCreatePassword'))
const Dashboard = lazy(() => import('../src/screens/website/Dashboard'))
const NotificationList = lazy(() => import('./screens/notifications/NotificationList'))
const NotificationDetail = lazy(() => import('./screens/notifications/NotificationDetail'))
const Profile = lazy(() => import('./screens/website/Profile'))
const VerifyEmail = lazy(() => import('./screens/auth/verify/VerifyEmail'))
const Security = lazy(() => import('./screens/website/Security'))
const MyPolicies = lazy(() => import('./screens/logged_user/policies/MyPolicies'))
const Transactions = lazy(() => import('./screens/logged_user/payment/transactions/Transactions'))
const TravelPolicyDetail = lazy(() => import('./screens/logged_user/policies/detail/TravelPolicyDetail'))
const TplPolicyDetail = lazy(() => import('./screens/logged_user/policies/detail/TplPolicyDetail'))
const WelcomerPolicyDetail = lazy(() => import('./screens/logged_user/policies/detail/WelcomerPolicyDetail'))
const PetPolicyDetail = lazy(() => import('./screens/logged_user/policies/detail/PetPolicyDetail'))
const TermsAndConditions = lazy(() => import('./screens/website/TermsAndConditions'))
const Privacy = lazy(() => import('./screens/website/Privacy'))
const Consent = lazy(() => import('./screens/website/Consent'))
const AboutUs = lazy(() => import('./screens/website/AboutUs'))
const FAQ = lazy(() => import('./screens/website/FAQ'))
const OurPartners = lazy(() => import('./screens/website/OurPartners'))
const SingleOrMulti = lazy(() => import('./screens/products/Travel/SingleOrMulti'))
const SingleSelectDate = lazy(() => import('./screens/products/Travel/SingleSelectDate'))
const SelectRegion = lazy(() => import('./screens/products/Travel/SelectRegion'))
const MultiSelectDate = lazy(() => import('./screens/products/Travel/MultiSelectDate'))
const CurrencyAndLimit = lazy(() => import('./screens/products/Travel/CurrencyAndLimit'))
const AddTravelers = lazy(() => import('./screens/products/Travel/AddTravelers'))
const ChooseMethod = lazy(() => import('./screens/products/Travel/ChooseMethod'))
const TravelOffers = lazy(() => import('./screens/products/Travel/TravelOffers'))
const TravelOffer = lazy(() => import('./screens/products/Travel/TravelOffer'))
const WhoDoYouInsure = lazy(() => import('./screens/products/Travel/WhoDoYouInsure'))
const AbroadForMe = lazy(() => import('./screens/products/Travel/AbroadForMe'))
const AbroadForOther = lazy(() => import('./screens/products/Travel/AbroadForOther'))
const AbroadInsurer = lazy(() => import('./screens/products/Travel/AbroadInsurer'))
const AbroadForMeAndOther = lazy(() => import('./screens/products/Travel/AbroadForMeAndOther'))
const CheckInformation = lazy(() => import('./screens/products/Travel/CheckInformation'))
const PolicyRegistration = lazy(() => import('./screens/products/Travel/PolicyRegistration'))
const AbroadPayment = lazy(() => import('./screens/products/Travel/AbroadPayment'))
const ProductDetail = lazy(() => import('./screens/products/Travel/ProductDetail'))
const ChooseDays = lazy(() => import('./screens/products/Tpl/ChooseDays'))
const WhichCurrency = lazy(() => import('./screens/products/Tpl/WhichCurrency'))
const ExtraServices = lazy(() => import('./screens/products/Tpl/ExtraServices'))
const WhichMethod = lazy(() => import('./screens/products/Tpl/WhichMethod'))
const TplOffers = lazy(() => import('./screens/products/Tpl/TplOffers'))
const TplOffer = lazy(() => import('./screens/products/Tpl/TplOffer'))
const InsuranceFor = lazy(() => import('./screens/products/Tpl/InsuranceFor'))
const TplForMe = lazy(() => import('./screens/products/Tpl/TplForMe'))
const TplForOther = lazy(() => import('./screens/products/Tpl/TplForOther'))
const TplInsurerInfo = lazy(() => import('./screens/products/Tpl/TplInsurerInfo'))
const TplCarInfo = lazy(() => import('./screens/products/Tpl/TplCarInfo'))
const TplCheckInformation = lazy(() => import('./screens/products/Tpl/TplCheckInformation'))
const TplPolicyRegistration = lazy(() => import('./screens/products/Tpl/TplPolicyRegistration'))
const TplPayment = lazy(() => import('./screens/products/Tpl/TplPayment'))
const HealthCompareCompanies = lazy(() => import('./screens/products/Health/HealthCompareCompanies'))
const HealthPriority = lazy(() => import('./screens/products/Health/HealthPriority'))
const HealthStartDate = lazy(() => import('./screens/products/Health/HealthStartDate'))
const HealthChooseMethod = lazy(() => import('./screens/products/Health/HealthChooseMethod'))
const HealthOffers = lazy(() => import('./screens/products/Health/HealthOffers'))
const HealthOffer = lazy(() => import('./screens/products/Health/HealthOffer'))
const HealthWhoDoYouInsure = lazy(() => import('./screens/products/Health/HealthWhoDoYouInsure'))
const HealthForMe = lazy(() => import('./screens/products/Health/HealthForMe'))
const HealthForOther = lazy(() => import('./screens/products/Health/HealthForOther'))
const HealthInsurer = lazy(() => import('./screens/products/Health/HealthInsurer'))
const HealthForMeAndOther = lazy(() => import('./screens/products/Health/HealthForMeAndOther'))
const HealthCheckInformation = lazy(() => import('./screens/products/Health/HealthCheckInformation'))
const HealthPolicyRegistration = lazy(() => import('./screens/products/Health/HealthPolicyRegistration'))
const HealthPayment = lazy(() => import('./screens/products/Health/HealthPayment'))
const CriticalStartDate = lazy(() => import('./screens/products/Critical-ilness/CriticalStartDate'))
const CriticalCurrencyAndLimit = lazy(() => import('./screens/products/Critical-ilness/CriticalCurrencyAndLimit'))
const CriticalChooseMethod = lazy(() => import('./screens/products/Critical-ilness/CriticalChooseMethod'))
const CriticalWhoDoYouInsure = lazy(() => import('./screens/products/Critical-ilness/CriticalWhoDoYouInsure'))
const CriticalForMe = lazy(() => import('./screens/products/Critical-ilness/CriticalForMe'))
const CriticalForOther = lazy(() => import('./screens/products/Critical-ilness/CriticalForOther'))
const CriticalForMeAndOther = lazy(() => import('./screens/products/Critical-ilness/CriticalForMeAndOther'))
const CriticalInsurer = lazy(() => import('./screens/products/Critical-ilness/CriticalInsurer'))
const CriticalOffer = lazy(() => import('./screens/products/Critical-ilness/CriticalOffer'))
const CriticalOffers = lazy(() => import('./screens/products/Critical-ilness/CriticalOffers'))
const CriticalAddInsured = lazy(() => import('./screens/products/Critical-ilness/CriticalAddInsured'))
const CriticalPolicyRegistration = lazy(() => import('./screens/products/Critical-ilness/CriticalPolicyRegistration'))
const CriticalCheckInformation = lazy(() => import('./screens/products/Critical-ilness/CriticalCheckInformation'))
const CriticalPayment = lazy(() => import('./screens/products/Critical-ilness/CriticalPayment'))
const WelcomerStartDate = lazy(() => import('./screens/products/Welcomer/WelcomerStartDate'))
const WelcomerCurrencyAndLimit = lazy(() => import('./screens/products/Welcomer/WelcomerCurrencyAndLimit'))
const WelcomerAddTravelers = lazy(() => import('./screens/products/Welcomer/WelcomerAddTravelers'))
const WelcomerChooseMethod = lazy(() => import('./screens/products/Welcomer/WelcomerChooseMethod'))
const WelcomerOffers = lazy(() => import('./screens/products/Welcomer/WelcomerOffers'))
const WelcomerOffer = lazy(() => import('./screens/products/Welcomer/WelcomerOffer'))
const WelcomerWhoDoYouInsure = lazy(() => import('./screens/products/Welcomer/WelcomerWhoDoYouInsure'))
const WelcomerForMe = lazy(() => import('./screens/products/Welcomer/WelcomerForMe'))
const WelcomerForOther = lazy(() => import('./screens/products/Welcomer/WelcomerForOther'))
const WelcomerInsurer = lazy(() => import('./screens/products/Welcomer/WelcomerInsurer'))
const WelcomerForMeAndOther = lazy(() => import('./screens/products/Welcomer/WelcomerForMeAndOther'))
const WelcomerCheckInformation = lazy(() => import('./screens/products/Welcomer/WelcomerCheckInformation'))
const WelcomerPolicyRegistration = lazy(() => import('./screens/products/Welcomer/WelcomerPolicyRegistration'))
const WelcomerPayment = lazy(() => import('./screens/products/Welcomer/WelcomerPayment'))
const BusinessLanding = lazy(() => import('./screens/business/BusinessLanding'))
const BusinessCategory = lazy(() => import('./screens/business/BusinessCategory'))
const CompanyContactInfo = lazy(() => import('./screens/business/business_products/CompanyContactInfo'))
const HealthCompanyInfo = lazy(() => import('./screens/business/business_products/HealthCompanyInfo'))
const CarCompanyInfo = lazy(() => import('./screens/business/business_products/CarCompanyInfo'))
const PropertyCompanyinfo = lazy(() => import('./screens/business/business_products/PropertyCompanyinfo'))
const OtherCompanyInfo = lazy(() => import('./screens/business/business_products/OtherCompanyInfo'))
const ChoosePet = lazy(() => import('./screens/products/pet/ChoosePet'))
const PetInfo = lazy(() => import('./screens/products/pet/PetInfo'))
const PetStartDate = lazy(() => import('./screens/products/pet/PetStartDate'))
const PetOfferMethod = lazy(() => import('./screens/products/pet/PetOfferMethod'))
const PetOffers = lazy(() => import('./screens/products/pet/PetOffers'))
const PetOffer = lazy(() => import('./screens/products/pet/PetOffer'))
const PetInsurer = lazy(() => import('./screens/products/pet/PetInsurer'))
const PetCheckInformation = lazy(() => import('./screens/products/pet/PetCheckInformation'))
const PetPolicyRegistration = lazy(() => import('./screens/products/pet/PetPolicyRegistration'))
const PetPayment = lazy(() => import('./screens/products/pet/PetPayment'))
const DownloadPolicies = lazy(() => import('./screens/website/DownloadPolicies'))
const ComingSoon = lazy(() => import('./components/v2/buttons/ComingSoon'))
//
// user route
function UserPrivateRoute({ children }: any) {
  const auth = useAppSelector((data) => data.auth)
  if (auth.userLoading) {
    return <Loader />
  }
  return auth.token && auth.user && ['User'].includes(auth.user.userType) ? children : <Navigate replace to="/login" />
}
//
const GOOGLE_TRACKING_ID = 'G-VLBVDQWE11'
ReactGA.initialize(GOOGLE_TRACKING_ID)
//
const App = () => {
  // update html lang att
  document.documentElement.lang = i18n?.language
  //
  const { t } = useTranslation()
  //
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: window.location.pathname.replace(/\//g, ' ').replace(/-/g, ' '),
    })
    return () => {}
  }, [])
  //
  const auth = useAppSelector((data) => data.auth)
  //
  const { lang } = useAppSelector((root: { app: AppState }) => root.app)
  //
  const dispatch = useAppDispatch()
  //
  const getUsurByTokenCallback = useCallback(() => {
    // TODO this gets called twice, even if `auth.token`'s value doesn't change
    if (auth.token) {
      const loadProfile = async () => {
        const {
          // status,
          // data,
          error: loadProfileError,
          //refetch
        } = await dispatch(api.endpoints.profile.initiate({ token: auth.token }))
        if (loadProfileError) {
          // console.log('login via token failed', { loadProfileError })
          dispatch(clearLoginData())
        }
      }
      loadProfile()
    }
  }, [auth.token, dispatch])
  //
  useEffect(() => {
    getUsurByTokenCallback()

    return () => {}
  }, [getUsurByTokenCallback])
  //
  //
  const { licenseKey, visibility, group } = useAppSelector((data: { liveChat: InitialStateType }) => data.liveChat)
  //
  useEffect(() => {
    DayjsLocalManager(lang === 'ge' ? 'ka' : lang)
    //
  }, [dispatch, lang])
  //
  //
  const setIsProductionCallback = useCallback(() => {
    //
    const hostname = window.location.hostname
    const isProduction = hostname === 'frani.com' || hostname === 'www.frani.com' ? true : false
    //
    dispatch(setIsProduction(isProduction))
  }, [dispatch])
  //
  useEffect(() => {
    setIsProductionCallback()
    return () => {}
  }, [setIsProductionCallback])
  //
  //
  const PathName = window.location.hostname
  //
  // pin code
  const [pinCode] = useState<string | null>(localStorage.getItem('pin'))
  //
  //
  return (
    <>
      {(pinCode === '78651' || pinCode === null) && PathName === 'dev.frani.com' ? (
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<ComingSoon title={t('pinTitle')} message={t('pinDescription')} />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <LiveChatWidget
            onVisibilityChanged={(event) => dispatch(setLiveChatVisibility(event.visibility))}
            license={licenseKey}
            group={group}
            visibility={visibility}
          />

          <Routes>
            {/* landing screen */}
            <Route
              path="/"
              element={
                <Layout>
                  <Landing />
                </Layout>
              }
            />
            {/* category screen */}
            <Route path="/category" element={<Category />} />
            {/* login screen */}
            <Route path="/login" element={<Login />} />
            {/* payment faulure */}
            <Route path="/pay/payment-failure" element={<PayFailureScreen />} />
            {/* payment successful */}
            <Route path="/pay/payment-success" element={<PaySuccessful />} />
            {/* reset password step one screen */}
            <Route path="/reset-password" element={<ResetPasswordStepOne />} />
            {/* reset password step two screen*/}
            <Route path="/reset-password-create/:token" element={<ResetPasswordStepTwo />} />
            {/*  */}
            <Route path="/auth/personal-info" element={<SignUpPersonalInfo />} />
            {/*  */}
            <Route path="/auth/create-password" element={<SignUpCreatePassword />} />
            {/* dashboard screen */}
            <Route
              path="/dashboard"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <Dashboard />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />
            {/* notifications page */}
            <Route
              path="/notification-list"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <NotificationList />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />
            {/* notification detailed page */}
            <Route
              path="/notification-details"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <NotificationDetail />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />
            {/* profile screen */}
            <Route
              path="/profile"
              element={
                <UserPrivateRoute>
                  <Profile />
                </UserPrivateRoute>
              }
            />
            {/* user verify email */}
            <Route path="/verify-email/*" element={<VerifyEmail />} />
            <Route
              path="/security"
              element={
                <UserPrivateRoute>
                  <Security />
                </UserPrivateRoute>
              }
            />
            {/* logged user policies */}
            <Route
              path="/my-policies"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <MyPolicies />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />
            {/* logged user Transactions */}
            <Route
              path="/transactions"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <Transactions />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />
            {/* logged user Policy detailed page */}
            <Route
              path="/travel-policy-detail/:policyId"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <TravelPolicyDetail />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />

            {/* logged user TPL Policy detailed page */}
            <Route
              path="/tpl-policy-detail/:policyId"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <TplPolicyDetail />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />

            {/* logged user Welcomer Policy detailed page */}
            <Route
              path="/welcomer-policy-detail/:policyId"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <WelcomerPolicyDetail />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />

            {/* logged user Pet Policy detailed page */}
            <Route
              path="/pet-policy-detail/:policyId"
              element={
                <UserPrivateRoute>
                  <LoggedUserLayout>
                    <PetPolicyDetail />
                  </LoggedUserLayout>
                </UserPrivateRoute>
              }
            />

            {/* terms */}
            <Route
              path="/terms-and-conditions"
              element={
                <Layout>
                  <TermsAndConditions />
                </Layout>
              }
            />
            {/* Privacy */}
            <Route
              path="/privacy"
              element={
                <Layout>
                  <Privacy />
                </Layout>
              }
            />
            {/* Privacy */}
            <Route
              path="/consent"
              element={
                <Layout>
                  <Consent />
                </Layout>
              }
            />
            {/* about us */}
            <Route
              path="/about-us"
              element={
                <Layout>
                  <AboutUs />
                </Layout>
              }
            />
            {/* FAQ */}
            <Route
              path="/faq"
              element={
                <Layout>
                  <FAQ />
                </Layout>
              }
            />

            <Route
              path="/our-partners"
              element={
                <Layout>
                  <OurPartners />
                </Layout>
              }
            />

            {/*########### !TAVEL FLOW  ###########*/}

            <Route path="/single-or-multi" element={<SingleOrMulti />} />

            <Route path="/single-select-date" element={<SingleSelectDate />} />

            <Route path="/select-region" element={<SelectRegion />} />

            <Route path="/multi-select-date" element={<MultiSelectDate />} />

            <Route path="/currency-and-limit" element={<CurrencyAndLimit />} />

            <Route path="/add-travelers" element={<AddTravelers />} />

            <Route path="/choose-method" element={<ChooseMethod />} />

            <Route path="/travel-offers" element={<TravelOffers />} />

            <Route path="/travel-offer" element={<TravelOffer />} />

            <Route path="/who-do-you-insure" element={<WhoDoYouInsure />} />

            <Route path="/abroad-for-me" element={<AbroadForMe />} />

            <Route path="/abroad-for-other" element={<AbroadForOther />} />

            <Route path="/abroad-insurer" element={<AbroadInsurer />} />

            <Route path="/abroad-for-me-and-other" element={<AbroadForMeAndOther />} />

            <Route path="/abroad-check-information" element={<CheckInformation />} />

            <Route path="/policy-registration" element={<PolicyRegistration />} />

            <Route path="/abroad-payment" element={<AbroadPayment />} />

            <Route
              path="/:type/product-detail"
              element={
                <Layout>
                  <ProductDetail />
                </Layout>
              }
            />
            {/*########### !TAVEL FLOW ###########*/}

            {/*########### !TPL FLOW  ###########*/}

            <Route path="/tpl-choose-days" element={<ChooseDays />} />

            <Route path="/tpl-which-currency" element={<WhichCurrency />} />

            <Route path="/tpl-extra-services" element={<ExtraServices />} />

            <Route path="/tpl-which-method" element={<WhichMethod />} />

            <Route path="/tpl-offers" element={<TplOffers />} />

            <Route path="/tpl-offer" element={<TplOffer />} />

            <Route path="/tpl-insurance-for" element={<InsuranceFor />} />

            <Route path="/tpl-for-me" element={<TplForMe />} />

            <Route path="/tpl-for-other" element={<TplForOther />} />

            <Route path="/tpl-insurer" element={<TplInsurerInfo />} />

            <Route path="/tpl-car-info" element={<TplCarInfo />} />

            <Route path="/tpl-check-information" element={<TplCheckInformation />} />

            <Route path="/tpl-policy-registration" element={<TplPolicyRegistration />} />

            <Route path="/tpl-payment" element={<TplPayment />} />
            {/*########### !TPL FLOW  ###########*/}

            {/*########### !Health FLOW  ###########*/}
            <Route path="/health-compare-companies" element={<HealthCompareCompanies />} />

            <Route path="/health-priority" element={<HealthPriority />} />

            <Route path="/health-start-date" element={<HealthStartDate />} />

            <Route path="/health-choose-method" element={<HealthChooseMethod />} />

            {/* <Route path="/health-payment-schedule" element={<HealthPaymentSchedule />} /> */}

            <Route path="/health-offers" element={<HealthOffers />} />

            <Route path="/health-offer" element={<HealthOffer />} />

            <Route path="/health-who-do-you-insure" element={<HealthWhoDoYouInsure />} />

            <Route path="/health-for-me" element={<HealthForMe />} />

            <Route path="/health-for-other" element={<HealthForOther />} />

            <Route path="/health-insurer" element={<HealthInsurer />} />

            <Route path="/health-for-me-and-other" element={<HealthForMeAndOther />} />

            <Route path="/health-check-information" element={<HealthCheckInformation />} />

            <Route path="/health-policy-registration" element={<HealthPolicyRegistration />} />

            <Route path="/health-payment" element={<HealthPayment />} />

            {/*########### !HEALTH FLOW  ###########*/}

            {/*########### !CRITICAL ILLNESS FLOW  ###########*/}
            <Route path="/critical-start-date" element={<CriticalStartDate />} />

            <Route path="/critical-choose-currency" element={<CriticalCurrencyAndLimit />} />

            <Route path="/critical-choose-method" element={<CriticalChooseMethod />} />

            <Route path="/critical-who-do-you-insure" element={<CriticalWhoDoYouInsure />} />

            <Route path="/critical-for-me" element={<CriticalForMe />} />

            <Route path="/critical-for-other" element={<CriticalForOther />} />

            <Route path="/critical-for-me-and-other" element={<CriticalForMeAndOther />} />

            <Route path="/critical-insurer" element={<CriticalInsurer />} />

            <Route path="/critical-offer" element={<CriticalOffer />} />

            <Route path="/critical-offers" element={<CriticalOffers />} />

            <Route path="/critical-add-insured" element={<CriticalAddInsured />} />

            <Route path="/critical-policy-registration" element={<CriticalPolicyRegistration />} />

            <Route path="/critical-check-information" element={<CriticalCheckInformation />} />

            <Route path="/critical-payment" element={<CriticalPayment />} />

            {/*########### !CRITICAL HEALTH FLOW  ###########*/}

            {/*########### !WELCOMER INSURANCE FLOW  ###########*/}

            <Route path="/welcomer-start-date" element={<WelcomerStartDate />} />

            <Route path="/welcomer-currency-and-limit" element={<WelcomerCurrencyAndLimit />} />

            <Route path="/welcomer-add-travelers" element={<WelcomerAddTravelers />} />

            <Route path="/welcomer-choose-method" element={<WelcomerChooseMethod />} />

            <Route path="/welcomer-offers" element={<WelcomerOffers />} />

            <Route path="/welcomer-offer" element={<WelcomerOffer />} />

            <Route path="/welcomer-who-do-you-insure" element={<WelcomerWhoDoYouInsure />} />

            <Route path="/welcomer-for-me" element={<WelcomerForMe />} />

            <Route path="/welcomer-for-other" element={<WelcomerForOther />} />

            <Route path="/welcomer-insurer" element={<WelcomerInsurer />} />

            <Route path="/welcomer-for-me-and-other" element={<WelcomerForMeAndOther />} />

            <Route path="/welcomer-check-information" element={<WelcomerCheckInformation />} />

            <Route path="/welcomer-policy-registration" element={<WelcomerPolicyRegistration />} />

            <Route path="/welcomer-payment" element={<WelcomerPayment />} />

            {/*########### !WELCOMER INSURANCE FLOW  ###########*/}

            {/* ########### !BUSINESS INSURANCE FLOW  ########### */}
            <Route
              path="business"
              element={
                <Layout>
                  <BusinessLanding />
                </Layout>
              }
            />
            <Route
              path="business-category"
              element={
                <Layout>
                  <BusinessCategory />
                </Layout>
              }
            />
            <Route path="/company-contact-info" element={<CompanyContactInfo />} />
            <Route path="/health-company-info" element={<HealthCompanyInfo />} />
            <Route path="/car-company-info" element={<CarCompanyInfo />} />
            <Route path="/property-company-info" element={<PropertyCompanyinfo />} />
            <Route path="/other-company-info" element={<OtherCompanyInfo />} />
            {/* ########### !BUSINESS INSURANCE FLOW  ########### */}

            {/* ########### PET FLOW  ########### */}
            <Route path="/choose-pet" element={<ChoosePet />} />

            <Route path="/pet-info" element={<PetInfo />} />

            <Route path="/pet-start-date" element={<PetStartDate />} />

            <Route path="/pet-offer-method" element={<PetOfferMethod />} />

            <Route path="/pet-offers" element={<PetOffers />} />

            <Route path="/pet-offer" element={<PetOffer />} />

            <Route path="/pet-insurer" element={<PetInsurer />} />

            <Route path="/pet-check-information" element={<PetCheckInformation />} />

            <Route path="/pet-policy-registration" element={<PetPolicyRegistration />} />

            <Route path="/pet-payment" element={<PetPayment />} />

            {/* ########### PET FLOW  ########### */}

            {/* consent for dialog */}
            <Route path="/consent-dialog" element={<Consent dialog={true} />} />

            {/* terms for dialog */}
            <Route path="/terms-and-conditions-dialog" element={<TermsAndConditions dialog={true} />} />
            <Route path="/policies/:encode" element={<DownloadPolicies />} />

            {/* some routes */}
            <Route path="*" element={<Navigate to={`/`} replace />} />
            {/* some routes */}
          </Routes>
        </BrowserRouter>
      )}
    </>
  )
}

export default App
