import { createSlice, PayloadAction } from '@reduxjs/toolkit'
//
//
export type InitialStateType = {
  visibility: 'maximized' | 'minimized' | 'hidden'
  licenseKey: string
  group: string
}
//
//
const initialState: InitialStateType = {
  visibility: 'minimized',
  licenseKey: '14898243',
  group: '0',
}
//
//
const slice = createSlice({
  name: 'liveChat',
  initialState,
  reducers: {
    //
    //
    setLiveChatVisibility: (
      state: InitialStateType,
      { payload: status }: PayloadAction<InitialStateType['visibility']>,
    ) => {
      state.visibility = status
    },
    //
    //
  },
})

export const { setLiveChatVisibility } = slice.actions

export default slice.reducer
