import 'bootstrap/dist/css/bootstrap.min.css'
import i18next from 'i18next'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import './App.css'
import CustomToast from './components/CustomToast'
import './i18n'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { store } from './store'
import './styles/fonts.css'
import { HelmetProvider } from 'react-helmet-async'

const isProduction = process.env.NODE_ENV === 'production' // Check if the environment is production
const allowedDomains = ['www.frani.com', 'frani.com', 'www.dev.frani.com', 'dev.frani.com'] // Define the allowed domains

if (isProduction && allowedDomains.includes(window.location.hostname)) {
  // Initialize Sentry only in production and for specific domains
  import('@sentry/react').then((Sentry) => {
    Sentry.init({
      dsn: 'https://3f9c59081bb4b1effb2975d53b64f2de@o4504769496023040.ingest.sentry.io/4505821317038080',
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      environment: 'production',
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <Suspense fallback={null}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
          <CustomToast />
        </Suspense>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
