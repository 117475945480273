import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import api from '../api'
import { User } from '../domain/types'
import { getAuthToken, setAuthToken } from './tokenStorage'

export type AuthState = {
  user: User | null
  userLoading: boolean
  token: string | null
}

// Get the token from local storage on initial load
const authTokenFromStorage = getAuthToken()

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: authTokenFromStorage,
    userLoading: !!authTokenFromStorage,
  } as AuthState,
  reducers: {
    setCredentials: (
      // Action to set user credentials upon login or registration
      state: AuthState,
      { payload: { user, token } }: PayloadAction<{ user: User; token: string }>,
    ) => {
      state.user = user
      state.userLoading = false
      state.token = token
      setAuthToken(token) // Save the token to local storage
    },
    clearLoginData: (
      // Action to clear user data upon logout or token expiry
      state: AuthState,
    ) => {
      state.token = null
      state.user = null
      state.userLoading = false
      setAuthToken('') // Clear the token from local storage
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        // Handle user state after successful registration
        api.endpoints.signUp.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token
          state.user = payload.user
          state.userLoading = false
          setAuthToken(payload.token)
        },
      )
      .addMatcher(
        // Set userLoading to true while login request is pending
        api.endpoints.login.matchPending,
        (state) => {
          state.userLoading = true
        },
      )
      .addMatcher(
        // Update state and local storage upon successful login
        api.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.token = payload.token
          state.user = payload.user
          state.userLoading = false
          setAuthToken(payload.token)
        },
      )
      .addMatcher(
        // Clear user state upon successful logout
        api.endpoints.logout.matchFulfilled,
        (state) => {
          state.token = null
          state.user = null
          state.userLoading = false
          setAuthToken('')
        },
      )
      .addMatcher(
        // Indicate loading state when profile data is being fetched
        api.endpoints.profile.matchPending,
        (state) => {
          state.userLoading = true
        },
      )
      .addMatcher(
        // Update user data after successful profile fetch
        api.endpoints.profile.matchFulfilled,
        (state, { payload }) => {
          state.user = payload
          state.userLoading = false
        },
      )
      .addMatcher(
        // Handle rejected state when profile fetch fails
        api.endpoints.profile.matchRejected,
        (state) => {
          state.token = null
          state.user = null
          state.userLoading = false
          setAuthToken('')
        },
      )
      .addMatcher(
        // Update user state upon successful profile update
        api.endpoints.updateProfile.matchFulfilled,
        (state, { payload }) => {
          state.user = payload
          state.userLoading = false
        },
      )
  },
})

export const { setCredentials, clearLoginData } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user
