import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CriticalIllnessOffer,
  EnabledLanguage,
  CriticalIllnessForMePersonalInfo,
  ProgressBarType,
} from '../domain/types'
//
//
type InsuredInfo = {
  id: number
  age: number
  date: string | null
  dateFormat: string | null
  gender: string | null
}

type InsurerDate = {
  age: number
  date: string | null
  dateFormat: string | null
}

export type CriticalIllnessFlowFilter = {
  betterOffer: boolean
  dates: {
    start: Date | string | null
    end: Date | string | null
  }
  productCategory: string
  currency?: string
  limit?: number | null
  insuredInfo: InsuredInfo[]
  insurerDate: InsurerDate
  chooseMethod: string
  chooseDays: string
  selectedCompanies: number[] | []
  selectedPackageServices: number[] | []
  leftCompanyIds: number[] | []
  servicePriorities: number[] | []
  priceRanges: string[] | []
  priorities: number[] | []
  paymentSchedule: {
    id: number | null
    type: string | null
  }
  paymentScheduleId: number
  uniqueId: string
  offer?: CriticalIllnessOffer
  companyOffers?: CriticalIllnessOffer[]
  offerTotalPrice?: number
}
//
//
export type CriticalIllnessCheckoutFlow = {
  forWho: string
  insurer: Partial<CriticalIllnessForMePersonalInfo>
  insured?: Partial<CriticalIllnessForMePersonalInfo>[]
  orderId: number
  totalPrice: number
}
//
//
export type CriticalIllnessState = {
  filter: Partial<CriticalIllnessFlowFilter>
  checkout: CriticalIllnessCheckoutFlow
  screenHeader: {
    totalCount: number
    passedCount: number
  }
  progress: ProgressBarType[]
}
//
//
//
//
const realInitialState: CriticalIllnessState = {
  filter: {
    productCategory: '',
    currency: '',
    limit: null,
    selectedPackageServices: [],
    selectedCompanies: [],
    servicePriorities: [],
    chooseMethod: '',
    chooseDays: '',
    //
    betterOffer: false,
    insuredInfo: [
      {
        id: 1,
        age: 0,
        date: '',
        dateFormat: '',
        gender: '',
      },
    ],
    insurerDate: {
      age: 0,
      date: '',
      dateFormat: '',
    },
    paymentSchedule: {
      id: 1,
      type: 'MONTHLY',
    },
    leftCompanyIds: [],
    offer: {
      brandName: {
        en: {
          title: 'Aldagi',
        },
        ge: {
          title: 'ალდაგი',
        },
      },
      companyId: 1,
      companyLogo: 'https://d3cd9cyzr3ifxh.cloudfront.net/icons/1a.4r7j57rfd-Aldagi.svg',
      companyName: 'ალდაგის სადაზღვევო',
      companySlug: 'aldagi',
      isBest: true,
      productConditions: '',
      productWording:
        'https://d3cd9cyzr3ifxh.cloudfront.net/files/1d.etn84nifqg-1c.3mf2bf2rdg-Aldagi---Travel-Insurance-Wording-2023-V1.pdf',
      healthRisks: [
        {
          healthRiskId: '1',
        },
        {
          healthRiskId: '2',
        },
      ],
      services: [
        {
          title: 'კიბო',
          covered: true,
        },
        {
          title: 'მიოკარდიუმის ინფარქტი',
          covered: true,
        },
        {
          title: 'ინსულტი',
          covered: true,
        },
        {
          title: 'ალცჰაიმერის დაავადება',
          covered: true,
        },
        {
          title: 'აორტის დაავადებების ქირურგია',
          covered: true,
        },
        {
          title: 'გულის სარქველის ტრანსპლანტაცია',
          covered: true,
        },
        {
          title: 'ბაქტერიული მენინგიტი',
          covered: true,
        },
        {
          title: 'პარკინსონის დაავადება',
          covered: true,
        },
        {
          title: 'მძიმე დამწვრობა',
          covered: true,
        },
        {
          title: 'თავის ტვინის ტრამვული დაზიანება',
          covered: true,
        },
        {
          title: 'თირკმლის უკმარისობა',
          covered: true,
        },
        {
          title: 'სასიცოცხლო ორგანოების ტრანსპლატაცია',
          covered: true,
        },
        {
          title: 'ენცეფალიტი',
          covered: true,
        },
        {
          title: 'პოლიომელიტი',
          covered: true,
        },
        {
          title: 'სრული მუდმივი შრომის უუნარობა',
          covered: true,
        },
        {
          title: 'კომა',
          covered: true,
        },
      ],
      calculatedPrices: {
        monthly: 8.54,
        annual: 102.48,
      },
    },
    companyOffers: [
      {
        brandName: {
          en: {
            title: 'Aldagi',
          },
          ge: {
            title: 'ალდაგი',
          },
        },
        companyId: 1,
        companyLogo: 'https://d3cd9cyzr3ifxh.cloudfront.net/icons/1a.4r7j57rfd-Aldagi.svg',
        companyName: 'ალდაგის სადაზღვევო',
        companySlug: 'aldagi',
        isBest: true,
        productConditions: '',
        productWording:
          'https://d3cd9cyzr3ifxh.cloudfront.net/files/1d.etn84nifqg-1c.3mf2bf2rdg-Aldagi---Travel-Insurance-Wording-2023-V1.pdf',
        healthRisks: [
          {
            healthRiskId: '1',
          },
          {
            healthRiskId: '2',
          },
        ],
        services: [
          {
            title: 'კიბო',
            covered: true,
          },
          {
            title: 'მიოკარდიუმის ინფარქტი',
            covered: true,
          },
          {
            title: 'ინსულტი',
            covered: true,
          },
          {
            title: 'ალცჰაიმერის დაავადება',
            covered: true,
          },
          {
            title: 'აორტის დაავადებების ქირურგია',
            covered: true,
          },
          {
            title: 'გულის სარქველის ტრანსპლანტაცია',
            covered: true,
          },
          {
            title: 'ბაქტერიული მენინგიტი',
            covered: true,
          },
          {
            title: 'პარკინსონის დაავადება',
            covered: true,
          },
          {
            title: 'მძიმე დამწვრობა',
            covered: true,
          },
          {
            title: 'თავის ტვინის ტრამვული დაზიანება',
            covered: true,
          },
          {
            title: 'თირკმლის უკმარისობა',
            covered: true,
          },
          {
            title: 'სასიცოცხლო ორგანოების ტრანსპლატაცია',
            covered: true,
          },
          {
            title: 'ენცეფალიტი',
            covered: true,
          },
          {
            title: 'პოლიომელიტი',
            covered: true,
          },
          {
            title: 'სრული მუდმივი შრომის უუნარობა',
            covered: true,
          },
          {
            title: 'კომა',
            covered: true,
          },
        ],
        calculatedPrices: {
          monthly: 8.54,
          annual: 102.48,
        },
      },
      {
        brandName: {
          en: {
            title: 'Ardi',
          },
          ge: {
            title: 'არდი',
          },
        },
        companyId: 3,
        companyLogo: 'https://d3cd9cyzr3ifxh.cloudfront.net/icons/1o.b9daovm4p8-Logo---Ardi.svg',
        companyName: 'არდის სადაზღვევო',
        companySlug: 'ardi',
        isBest: false,
        productConditions: '',
        productWording: 'https://d3cd9cyzr3ifxh.cloudfront.net/files/18.n5i2mhvc2o-ARDI---Travel-Wording.pdf',
        healthRisks: [
          {
            healthRiskId: '1',
          },
          {
            healthRiskId: '2',
          },
        ],
        services: [
          {
            title: 'კიბო',
            covered: true,
          },
          {
            title: 'მიოკარდიუმის ინფარქტი',
            covered: true,
          },
          {
            title: 'ინსულტი',
            covered: true,
          },
          {
            title: 'ალცჰაიმერის დაავადება',
            covered: true,
          },
          {
            title: 'აორტის დაავადებების ქირურგია',
            covered: true,
          },
          {
            title: 'გულის სარქველის ტრანსპლანტაცია',
            covered: true,
          },
          {
            title: 'ბაქტერიული მენინგიტი',
            covered: true,
          },
          {
            title: 'პარკინსონის დაავადება',
            covered: true,
          },
          {
            title: 'მძიმე დამწვრობა',
            covered: true,
          },
          {
            title: 'თავის ტვინის ტრამვული დაზიანება',
            covered: true,
          },
          {
            title: 'თირკმლის უკმარისობა',
            covered: true,
          },
          {
            title: 'სასიცოცხლო ორგანოების ტრანსპლატაცია',
            covered: true,
          },
          {
            title: 'ენცეფალიტი',
            covered: true,
          },
          {
            title: 'პოლიომელიტი',
            covered: true,
          },
          {
            title: 'სრული მუდმივი შრომის უუნარობა',
            covered: true,
          },
          {
            title: 'კომა',
            covered: true,
          },
        ],
        calculatedPrices: {
          monthly: 10,
          annual: 120,
        },
      },
    ],
    offerTotalPrice: undefined,
  },
  //
  checkout: {
    forWho: '',
    insurer: {
      cityzenship: 'GE',
    },
    insured: undefined,
    orderId: 0,
    totalPrice: 0,
  },
  screenHeader: {
    totalCount: 9,
    passedCount: 1,
  },
  progress: [
    {
      id: 1,
      title: '',
      url: '/critical-start-date',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 2,
      title: '',
      url: '/critical-choose-currency',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 3,
      title: '',
      url: '/critical-add-insured',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 4,
      title: '',
      url: '/critical-choose-method',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 5,
      title: '',
      url: '/critical-offers',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 6,
      title: '',
      url: '/critical-offer',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 7,
      title: '',
      url: '/critical-who-do-you-insure',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 8,
      title: '',
      url: '/critical-for-me',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 9,
      title: '',
      url: '/critical-for-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 10,
      title: '',
      url: '/critical-insurer',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 11,
      title: '',
      url: '/critical-for-me-and-other',
      active: false,
      passed: false,
      isVisible: false,
    },
    {
      id: 12,
      title: '',
      url: '/critical-check-information',
      active: false,
      passed: false,
      isVisible: true,
    },
    {
      id: 13,
      title: '',
      url: '/critical-policy-registration',
      active: false,
      passed: false,
      isVisible: true,
    },
  ],
}
//
//
const initialState = realInitialState
//
//
const slice = createSlice({
  name: 'criticalIllnessSlice',
  initialState,
  reducers: {
    //
    // BEGIN filter actions
    clearCriticalIllnessStore: (state: CriticalIllnessState, actionPayload: PayloadAction<undefined>) => {
      state.filter = initialState.filter
    },
    //
    setUpdateCriticalProgress: (state: CriticalIllnessState, action: PayloadAction<{ title: string; id: number }>) => {
      //
      const { title, id } = action.payload
      //
      const updated = state.progress.map((item) => (item.id === id ? { ...item, title } : item))
      //
      const changeStatus = updated.map((item) => {
        //
        if (item.id === id) {
          // If the item's id matches the provided id, update accordingly
          return {
            ...item,
            active: true,
            passed: true,
          }
        } else if (item.id && item.id < id) {
          // If the item's id is less than the provided id, mark as passed
          return {
            ...item,
            active: false,
            passed: true,
          }
        } else {
          // If the item's id is greater than the provided id, mark as not passed or active
          return {
            ...item,
            active: false,
            passed: false,
          }
        }
      })
      //
      state.progress = changeStatus
      //
    },
    //
    setCriticalVisibleStep: (
      state: CriticalIllnessState,
      action: PayloadAction<{ id: number; isVisible: boolean }>,
    ) => {
      //
      const { id, isVisible } = action.payload
      //
      const updatedProgress = state.progress?.map((item) => (item.id === id ? { ...item, isVisible } : item))
      //
      state.progress = updatedProgress
      //
    },

    //
    setProductCategory: (
      state: CriticalIllnessState,
      { payload: productCategory }: PayloadAction<CriticalIllnessState['filter']['productCategory']>,
    ) => {
      state.filter = {
        ...state.filter,
        productCategory,
      }
    },
    //
    setUniquedId: (
      state: CriticalIllnessState,
      { payload: uniqueId }: PayloadAction<CriticalIllnessState['filter']['uniqueId']>,
    ) => {
      state.filter = {
        ...state.filter,
        uniqueId,
      }
    },
    //
    setOfferTotalPrice: (
      state: CriticalIllnessState,
      { payload: offerTotalPrice }: PayloadAction<CriticalIllnessState['filter']['offerTotalPrice']>,
    ) => {
      state.filter = {
        ...state.filter,
        offerTotalPrice,
      }
    },
    //
    //
    setHeaderPassedCount: (
      state: CriticalIllnessState,
      { payload: passedCount }: PayloadAction<CriticalIllnessState['screenHeader']['passedCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        passedCount,
      }
    },
    //
    setHeaderTotalCount: (
      state: CriticalIllnessState,
      { payload: totalCount }: PayloadAction<CriticalIllnessState['screenHeader']['totalCount']>,
    ) => {
      state.screenHeader = {
        ...state.screenHeader,
        totalCount,
      }
    },
    //
    //
    setBetterOffer: (
      state: CriticalIllnessState,
      { payload: betterOffer }: PayloadAction<CriticalIllnessState['filter']['betterOffer']>,
    ) => {
      state.filter = {
        ...state.filter,
        betterOffer,
      }
    },
    //
    setCurrency: (
      state: CriticalIllnessState,
      { payload: currency }: PayloadAction<CriticalIllnessState['filter']['currency']>,
    ) => {
      state.filter = {
        ...state.filter,
        currency,
      }
    },
    //
    setLimit: (
      state: CriticalIllnessState,
      { payload: limit }: PayloadAction<CriticalIllnessState['filter']['limit']>,
    ) => {
      state.filter = {
        ...state.filter,
        limit,
      }
    },
    //
    setOffer: (
      state: CriticalIllnessState,
      { payload: offer }: PayloadAction<CriticalIllnessState['filter']['offer']>,
    ) => {
      state.filter = {
        ...state.filter,
        offer,
      }
    },
    //
    addEmptyInsured: (
      state: CriticalIllnessState,
      { payload }: PayloadAction<CriticalIllnessState['filter']['insuredInfo']>,
    ) => {
      //
      const lastItemId = state.filter?.insuredInfo?.[state.filter?.insuredInfo?.length - 1]?.id || 0
      //
      state.filter = {
        ...state.filter,
        insuredInfo: [
          ...(state.filter.insuredInfo || []),
          {
            id: lastItemId + 1,
            age: 0,
            date: null,
            dateFormat: null,
            gender: null,
          },
        ],
      }
    },

    updateInsuredAtIndex: (
      state: CriticalIllnessState,
      { payload }: PayloadAction<{ insuredIndex: number; age: number; date: string; dateFormat: string }>,
    ) => {
      const { insuredIndex, age, date, dateFormat } = payload
      //
      const updatedObject = state.filter.insuredInfo?.map((obj, index) =>
        index === insuredIndex ? { ...obj, age: age, date: date, dateFormat: dateFormat } : obj,
      )

      state.filter.insuredInfo = updatedObject
    },

    updateInsuredGenderAtIndex: (
      state: CriticalIllnessState,
      { payload }: PayloadAction<{ insuredIndex: number; gender: string }>,
    ) => {
      const { insuredIndex, gender } = payload

      const updatedObject = state.filter.insuredInfo?.map((obj, index) =>
        index === insuredIndex ? { ...obj, gender: gender } : obj,
      )

      state.filter.insuredInfo = updatedObject
    },

    removeInsuredAtIndex: (state: CriticalIllnessState, { payload: insuredIndex }: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        insuredInfo: (state.filter.insuredInfo || []).filter((item, index) => index !== insuredIndex),
      }
    },

    updateInsurer: (state: CriticalIllnessState, { payload }: PayloadAction<InsurerDate>) => {
      state.filter = {
        ...state.filter,
        insurerDate: payload,
      }
    },

    setChooseMethod: (
      state: CriticalIllnessState,
      { payload: chooseMethod }: PayloadAction<CriticalIllnessState['filter']['chooseMethod']>,
    ) => {
      state.filter = {
        ...state.filter,
        chooseMethod,
      }
    },
    //
    setChooseDays: (
      state: CriticalIllnessState,
      { payload: chooseDays }: PayloadAction<CriticalIllnessState['filter']['chooseDays']>,
    ) => {
      state.filter = {
        ...state.filter,
        chooseDays,
      }
    },
    //
    setCompanyOffers: (
      state: CriticalIllnessState,
      { payload: companyOffers }: PayloadAction<CriticalIllnessOffer[]>,
    ) => {
      state.filter = {
        ...state.filter,
        companyOffers,
      }
    },
    //
    setCompanyId: (
      state: CriticalIllnessState,
      { payload: selectedCompanies }: PayloadAction<CriticalIllnessState['filter']['selectedCompanies']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedCompanies,
      }
    },
    //
    setSelectedPackageServices: (
      state: CriticalIllnessState,
      { payload: selectedPackageServices }: PayloadAction<CriticalIllnessState['filter']['selectedPackageServices']>,
    ) => {
      state.filter = {
        ...state.filter,
        selectedPackageServices,
      }
    },
    setServicePriorities: (
      state: CriticalIllnessState,
      { payload: servicePriorities }: PayloadAction<CriticalIllnessState['filter']['servicePriorities']>,
    ) => {
      state.filter = {
        ...state.filter,
        servicePriorities,
      }
    },
    //
    //
    setLeftCompanyIds: (
      state: CriticalIllnessState,
      { payload: leftCompanyIds }: PayloadAction<CriticalIllnessState['filter']['leftCompanyIds']>,
    ) => {
      state.filter = {
        ...state.filter,
        leftCompanyIds,
      }
    },
    //
    setPriorities: (
      state: CriticalIllnessState,
      { payload: priorities }: PayloadAction<CriticalIllnessState['filter']['priorities']>,
    ) => {
      state.filter = {
        ...state.filter,
        priorities,
      }
    },
    //
    setPriceRanges: (
      state: CriticalIllnessState,
      { payload: priceRanges }: PayloadAction<CriticalIllnessState['filter']['priceRanges']>,
    ) => {
      state.filter = {
        ...state.filter,
        priceRanges,
      }
    },
    setPaymentScheduleInfo: (
      state: CriticalIllnessState,
      { payload: paymentSchedule }: PayloadAction<CriticalIllnessState['filter']['paymentSchedule']>,
    ) => {
      state.filter = {
        ...state.filter,
        paymentSchedule,
      }
    },
    setPaymentSchedule: (
      state: CriticalIllnessState,
      { payload: paymentScheduleId }: PayloadAction<CriticalIllnessState['filter']['paymentScheduleId']>,
    ) => {
      state.filter = {
        ...state.filter,
        paymentScheduleId,
      }
    },
    //
    //
    //
    // END filter actions
    //
    //
    //
    //
    // BEGIN checkout actions
    setForWho: (
      state: CriticalIllnessState,
      { payload: forWho }: PayloadAction<CriticalIllnessState['checkout']['forWho']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        forWho,
      }
    },
    //
    setInsured: (
      state: CriticalIllnessState,
      { payload: insured }: PayloadAction<CriticalIllnessState['checkout']['insured']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        insured: insured,
      }
    },
    //
    setInsurerInfo: (
      state: CriticalIllnessState,
      action: PayloadAction<{ fieldName: keyof CriticalIllnessState['checkout']['insurer']; fieldValue: any }>,
    ) => {
      const { fieldName, fieldValue } = action.payload
      state.checkout.insurer[fieldName] = fieldValue
    },
    //
    setInsurerObj: (
      state: CriticalIllnessState,
      { payload: insurer }: PayloadAction<CriticalIllnessState['checkout']['insurer']>,
    ) => {
      state.checkout.insurer = insurer
    },
    //
    setInsurerTranslationInfo: (
      state: CriticalIllnessState,
      action: PayloadAction<{
        fieldName: keyof { firstName: string; lastName: string }
        language: EnabledLanguage
        fieldValue: any
      }>,
    ) => {
      const { fieldName, fieldValue, language } = action.payload
      state.checkout.insurer = {
        ...state.checkout.insurer,
        translations: {
          ...state.checkout.insurer.translations,
          [language]: {
            ...state.checkout.insurer.translations?.[language],
            [fieldName]: fieldValue,
          },
        },
      }
    },
    //
    setOrderId: (
      state: CriticalIllnessState,
      { payload: orderId }: PayloadAction<CriticalIllnessState['checkout']['orderId']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        orderId,
      }
    },
    //
    setTotalPrice: (
      state: CriticalIllnessState,
      { payload: totalPrice }: PayloadAction<CriticalIllnessState['checkout']['totalPrice']>,
    ) => {
      state.checkout = {
        ...state.checkout,
        totalPrice,
      }
    },
    setDates: (
      state: CriticalIllnessState,
      { payload: dates }: PayloadAction<CriticalIllnessState['filter']['dates']>,
    ) => {
      state.filter = {
        ...state.filter,
        dates,
      }
    },
    //
    // END checkout actions
    //
  },
})

export const {
  // BEGIN filter actions
  setDates,
  clearCriticalIllnessStore,
  setProductCategory,
  setUniquedId,
  setUpdateCriticalProgress,
  setCriticalVisibleStep,
  setHeaderPassedCount,
  setHeaderTotalCount,
  setCurrency,
  setLimit,
  setOffer,
  setCompanyOffers,
  setSelectedPackageServices,
  addEmptyInsured,
  updateInsuredAtIndex,
  removeInsuredAtIndex,
  updateInsuredGenderAtIndex,
  updateInsurer,
  setChooseMethod,
  setChooseDays,
  setCompanyId,
  setLeftCompanyIds,
  setPriorities,
  setPriceRanges,
  setPaymentScheduleInfo,
  setPaymentSchedule,
  setBetterOffer,
  setOfferTotalPrice,
  // END filter actions
  //
  // BEGIN checjout actions
  setForWho,
  setInsurerInfo,
  setInsurerTranslationInfo,
  setInsurerObj,
  setOrderId,
  setTotalPrice,
  setInsured,
  // END checkout actions
} = slice.actions

export default slice.reducer
