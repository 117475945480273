import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  AboutUsType,
  AbroadOffer,
  AbroadOfferRequest,
  AddUser,
  AdminAuthorizedCompany,
  AdminCompany,
  AdminCompanyUser,
  AdminCurrentUserCompany,
  AdminProductCategoriesList,
  AdminTravelMultiDay,
  AdminTravelPolicyDetail,
  AdminWebDocument,
  CompaniesWithProduct,
  Company,
  CompanyProductsWithSubModal,
  Country,
  CreateNotification,
  DepartureAndArrival,
  DiscountList,
  DiscountedPaymentList,
  ExtraCoverages,
  FaqTypes,
  Footer,
  Franchise,
  FraniPartnerType,
  GeneratePolicyFilesProps,
  GeneratePolicyFilesType,
  InformationSheet,
  InsuranceKinds,
  LandingSlide,
  MainPageText,
  NotificationList,
  OurTeam,
  Package,
  PackageServices,
  PendingPolicyNewOrderResponse,
  PetBestOffer,
  PetBreed,
  PetCategories,
  PetLeftCompany,
  PetOfferPayload,
  PetPageInfo,
  PetServices,
  PolicyFilter,
  PolicyRegistrationFirstStepList,
  Product,
  ProductCategory,
  ProductCategoryEmail,
  ProductDetailType,
  ProfileEditableFields,
  PromoCode,
  PublicPolicy,
  RegisterPetPolicy,
  RegisterTplPolicyRequest,
  SgsRequestType,
  SgsType,
  SoldPolicies,
  StoreFraniUser,
  SubNotification,
  TplAndLocalTplList,
  TplBestOffer,
  TplCarInfo,
  TplCheckInfo,
  TplChoise,
  TplContactInfo,
  TplCurrencies,
  TplCurrencyList,
  TplInsurancePeriod,
  TplInsurancePeriodType,
  TplInsureChoice,
  TplInsuredPersonsInfo,
  TplLeftCompanies,
  TplOfferRequest,
  TplOfferResponse,
  TplOfferType,
  TplOffers,
  TplOrLocalTpl,
  TplPackage,
  TplPaymentMethod,
  TplPaymentSchedule,
  TplPersonalInfoType,
  TplPolicyRegisrationFirstStep,
  TplPolicyRegistration,
  TplProduct,
  TplServicesInfo,
  TplStartDateText,
  TplTransactions,
  Transation,
  TranslationsField,
  TravelAboardBestOfferPost,
  TravelAboardBestOfferResponse,
  TravelAboardDiscountPost,
  TravelAbroadPriceCombination,
  TravelAbrodLeftCompany,
  TravelAddon,
  TravelCurrencies,
  TravelDateText,
  TravelFranchise,
  TravelLimits,
  TravelMultiDayOption,
  TravelPackage,
  TravelPagesWording,
  TravelPolicyRegistration,
  TravelPolicyRegistrationResponse,
  TravelProduct,
  TravelProductsMinPrice,
  TravelRegions,
  TravelRisk,
  TravelSingleOrMultiPrice,
  User,
  UserCurrentPolicies,
  UserTransactions,
  UserTravelPolicyType,
  WebMenu,
  WebPage,
  WelcomerCurrencyList,
  WelcomerLeftCompany,
  WelcomerLimitsList,
  WelcomerOffers,
  WelcomerPagesInfo,
  WelcomerPolicyRegistration,
  WelcomerPolicyRegistrationResponse,
  WelcomerSearchDiscount,
  WelcomerSearchOffer,
  WhoWillChooseCompany,
} from '../domain/types'
import { RootState } from '../store'
import { detectUserLanguage } from '../store/langStorage'
import {
  BusinessCarInfo,
  BusinessHealthInfo,
  BusinessOtherInfo,
  BusinessPropertyInfo,
  TplCarInfoResponse,
  TplCompareRequest,
  TplCompareResponse,
} from './../domain/types'
//
//
export const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
/** after deployment, the api is `${same hostname}/api` */
// http://localhost:3333
// https://frani.com
// https://dev.frani.com
export const serverUrl = devMode ? 'https://dev.frani.com' : `${document.location.origin}`
export const apiUrl = `${serverUrl}/api/v1/`

export const uploadUrl = (uploadedFile: string) => {
  if (uploadedFile.startsWith('http')) {
    return uploadedFile
  } else {
    return `${serverUrl}${uploadedFile}`
  }
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      //
      const lang = detectUserLanguage()
      headers.set('locale', lang)
      //
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<{ user: User; token: string }, { email: string; password: string }>({
      query: (credentials) => ({
        url: 'signin',
        method: 'POST',
        body: credentials,
      }),
    }),
    //logout
    logout: builder.mutation({
      query: (args) => ({
        url: 'signout',
        method: 'POST',
        body: {},
      }),
    }),
    // reset password step one & enter email
    resetPasswordSeendEmail: builder.mutation<{ success: boolean }, { email: string }>({
      query: ({ email }) => ({
        url: 'forgot-password',
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    // reset password step two & send new password and token
    resetPasswordCreateNewPassword: builder.mutation<
      { success: boolean },
      { token: string; password: string; passwordConfirm: string }
    >({
      query: ({ token, password, passwordConfirm }) => ({
        url: `reset-password/${token}`,
        method: 'POST',
        body: {
          password,
          passwordConfirm,
        },
      }),
    }),
    // sign up
    signUp: builder.mutation<
      { user: User; token: string },
      {
        sgsVerification?: boolean
        firstName: string
        lastName: string
        firstNameGe?: string
        lastNameGe?: string
        phone: string
        personalNumber: string | null
        passportNumber: string | null
        email: string
        password: string
        passwordConfirm: string
        birthday: string
        citizenship: string
        gender: string
        consent: boolean
      }
    >({
      query: (userFields) => ({
        url: 'signup',
        method: 'POST',
        body: userFields,
      }),
    }),
    profile: builder.query<User, { token: string }>({
      query: ({ token }) => ({
        url: 'profile',
        method: 'GET',
      }),
    }),
    updateProfile: builder.mutation<User, ProfileEditableFields>({
      query: (userFields) => ({
        url: 'profile/personal-info/update',
        method: 'POST',
        body: userFields,
      }),
    }),
    updatePassword: builder.mutation<
      { message: string },
      { oldPassword: string; password: string; passwordConfirm: string; smsCode: number }
    >({
      query: (updatePasswordFields) => ({
        url: 'profile/password/update',
        method: 'POST',
        body: updatePasswordFields,
      }),
    }),
    companyList: builder.query<Company[], undefined>({
      query: () => ({
        url: 'admin/companies/list',
        method: 'GET',
      }),
    }),
    subNotification: builder.mutation<{ success: boolean }, SubNotification>({
      query: (notificationFields) => ({
        url: 'notification-subscription',
        method: 'POST',
        body: notificationFields,
      }),
    }),
    createNotification: builder.mutation<{ success: boolean }, CreateNotification>({
      query: (createNotificationFields) => ({
        url: 'admin/send-notifications',
        method: 'POST',
        body: createNotificationFields,
      }),
    }),
    updateNotifications: builder.mutation<
      User,
      {
        enableWebNotifications: boolean
        enableMobileNotifications: boolean
        enableEmailNotifications: boolean
        lang: string
      }
    >({
      query: (updateNotificationsFields) => ({
        url: 'profile/notifications/update',
        method: 'POST',
        body: updateNotificationsFields,
      }),
    }),
    getNotificationList: builder.query<NotificationList[], undefined>({
      query: () => ({
        url: 'profile/notifications',
        method: 'GET',
      }),
    }),
    getProductCategory: builder.query<ProductCategory[], undefined>({
      query: () => ({
        url: 'categories',
        method: 'GET',
      }),
    }),
    getFilterCompanies: builder.query<Company[], undefined>({
      query: () => ({
        url: 'companies',
        method: 'GET',
      }),
    }),
    getWebMenu: builder.query<WebMenu[], undefined>({
      query: () => ({
        url: 'admin/menu/list/1',
        method: 'GET',
      }),
    }),
    getAdminFooter: builder.query<[], undefined>({
      query: () => ({
        url: 'admin/menu/list/2',
        method: 'GET',
      }),
    }),
    getAdminMenu: builder.query<[], undefined>({
      query: () => ({
        url: 'admin/menu/list/3',
        method: 'GET',
      }),
    }),
    getAdminCurrentUserCompany: builder.query<AdminCurrentUserCompany, undefined>({
      query: () => ({
        url: 'admin/current-user-company',
        method: 'GET',
      }),
    }),
    getDepatureAndArrival: builder.query<DepartureAndArrival[], undefined>({
      query: () => ({
        url: 'travel-abroad-or-georgia',
        method: 'GET',
      }),
    }),
    getWhoWillChooseCompany: builder.query<WhoWillChooseCompany[], undefined>({
      query: () => ({
        url: 'who-will-choose-company',
        method: 'GET',
      }),
    }),
    getCompaniesWithProduct: builder.query<CompaniesWithProduct[], string>({
      query: (flyType) => ({
        url: `companies-with-product/${flyType}`,
        method: 'GET',
      }),
    }),
    getInsuranceKinds: builder.query<InsuranceKinds[], undefined>({
      query: () => ({
        url: `travel-single-or-multi`,
        method: 'GET',
      }),
    }),
    getTravelProductMinPrices: builder.query<TravelProductsMinPrice[], string>({
      query: (productType) => ({
        url: `travel-products-min-prices/${productType}`,
        method: 'GET',
      }),
    }),
    getTravelMultiDays: builder.query<TravelMultiDayOption[], undefined>({
      query: () => ({
        url: `travel-multi-days`,
        method: 'GET',
      }),
    }),
    getTravelRegions: builder.query<TravelRegions[], undefined>({
      query: () => ({
        url: `travel-areas`,
        method: 'GET',
      }),
    }),
    getTravelCurrencies: builder.query<TravelCurrencies[], string>({
      query: (currency) => ({
        url: `travel-currencies/${currency}`,
        method: 'GET',
      }),
    }),
    getTravelLimits: builder.query<TravelLimits[], undefined>({
      query: (_noArg) => ({
        url: `travel-abroad-limits`,
        method: 'GET',
      }),
    }),
    getTravelFranchises: builder.query<TravelFranchise[], string>({
      query: (travelType) => ({
        url: `package-franchises/${travelType}`,
        method: 'GET',
      }),
    }),
    getExtraCoverages: builder.query<ExtraCoverages[], { categorySlug: string; secondParam: string }>({
      query: ({ categorySlug, secondParam }) => ({
        url: `travel-attached-addons-with-min-prices/${categorySlug}/${secondParam}`,
        method: 'GET',
      }),
    }),
    getTravelSingleOrMultiPrice: builder.query<TravelSingleOrMultiPrice, undefined>({
      query: () => ({
        url: `travel-products-min-prices/travel-outside-georgia-package-prices`,
        method: 'GET',
      }),
    }),
    getCountries: builder.query<Country[], undefined>({
      query: () => ({
        url: `countries`,
        method: 'GET',
      }),
    }),
    travelAboardbestOffer: builder.mutation<TravelAboardBestOfferResponse[], TravelAboardBestOfferPost>({
      query: (offerParams) => ({
        url: 'travel-abroad-best-offer',
        method: 'POST',
        body: offerParams,
      }),
    }),
    // #################################
    // ADMIN
    // #################################
    // upload single file
    uploadFile: builder.mutation<
      { url: string }, // the uploaded file url
      { file: File }
    >({
      query: ({ file }) => {
        const uploadFormData = new FormData()
        uploadFormData.append('file', file)
        return {
          url: 'file/store',
          method: 'POST',
          body: uploadFormData,
        }
      },
    }),
    createTravelProduct: builder.mutation<{ message: string }, { companyId: number; productCategoryId: number }>({
      query: (travelProduct) => ({
        url: 'admin/travel-products/store',
        method: 'POST',
        body: travelProduct,
      }),
    }),
    getTravelPackageById: builder.query<TravelPackage, number>({
      query: (travelPackageId) => ({
        url: `admin/travel-packages/${travelPackageId}/details`,
        method: 'GET',
      }),
    }),
    createTravelPackage: builder.mutation<
      TravelPackage,
      { productId: number; translations: TranslationsField<{ title: string }> }
    >({
      query: (travelPackage) => ({
        url: 'admin/travel-packages/store',
        method: 'POST',
        body: travelPackage,
      }),
    }),
    // update travel package
    updateTravelPackage: builder.mutation<TravelPackage, TravelPackage>({
      query: (travelPackage) => ({
        url: `admin/travel-packages/update/${travelPackage.id}`,
        method: 'POST',
        body: travelPackage,
      }),
    }),
    // update travel package
    deleteTravelPackage: builder.mutation<{ message?: string }, TravelPackage['id']>({
      query: (travelPackageId) => ({
        url: `admin/travel-packages/delete/${travelPackageId}`,
        method: 'DELETE',
      }),
    }),
    // admin product categories list
    getProductCategoriesList: builder.query<AdminProductCategoriesList[], undefined>({
      query: () => ({
        url: '/product-categories/list',
        method: 'GET',
      }),
    }),
    // admin company products with sub modals
    getCompanyProductswithSubModals: builder.query<CompanyProductsWithSubModal[], number>({
      query: (companyId) => ({
        url: `admin/company/${companyId}/travel-products-with-sub-models`,
        method: 'GET',
      }),
    }),
    // admin company products with sub modals
    getProductListByCategoryId: builder.query<Product[], number>({
      query: (companyId) => ({
        url: `admin/company/${companyId}/product/list`,
        method: 'GET',
      }),
    }),
    // get product by id
    getProductById: builder.query<Product, number>({
      query: (productId) => ({
        url: `admin/products/current/${productId}`,
        method: 'GET',
      }),
    }),
    // get travel product by id
    getTravelProductById: builder.query<TravelProduct, number>({
      query: (productId) => ({
        url: `admin/travel-products/${productId}/details`,
        method: 'GET',
      }),
    }),
    // travel product tab one product fields store = save
    storeTravelProductTabOneProductFields: builder.mutation<TravelPolicyRegistrationResponse, TravelProduct>({
      query: (travelProduct) => ({
        url: `admin/travel-products/${travelProduct.id}/update`,
        method: 'POST',
        body: travelProduct,
      }),
    }),
    updateProductDocuments: builder.mutation<
      Product,
      {
        id: number
        conditionFileUrl?: string
        wordingFileUrl?: string
        policyFileUrl?: string
      }
    >({
      query: (newFileUrls) => ({
        url: `admin/products/update-documents/${newFileUrls.id}`,
        method: 'POST',
        body: newFileUrls,
      }),
    }),
    updateTravelProductPriceCombinations: builder.mutation<
      TravelAbroadPriceCombination[],
      Pick<TravelProduct, 'id' | 'priceCombinations' | 'priceCoefficients' | 'minTotalPremiumInfos' | 'dayGroupInfos'>
    >({
      query: (travelProductCoefficientFields) => ({
        url: `admin/travel-products/${travelProductCoefficientFields.id}/update-price-combinations`,
        method: 'POST',
        body: travelProductCoefficientFields,
      }),
    }),
    // admin: approve or reject product data entered by insurance companies
    adminActivateProduct: builder.mutation<Product, Product['id']>({
      query: (productId) => ({
        url: `admin/product/${productId}/activate`,
        method: 'POST',
        body: {},
      }),
    }),
    // admin: deactivate (reject changes on) product data entered by insurance companies
    adminDeactivateProduct: builder.mutation<Product, Product['id']>({
      query: (productId) => ({
        url: `admin/product/${productId}/deactivate`,
        method: 'POST',
        body: {},
      }),
    }),
    //
    // admin: approve or reject package data entered by insurance companies
    adminActivatePackage: builder.mutation<Package, Package['id']>({
      query: (packageId) => ({
        url: `admin/package/${packageId}/activate`,
        method: 'POST',
        body: {},
      }),
    }),
    // admin: deactivate (reject changes on) package data entered by insurance companies
    adminDeactivatePackage: builder.mutation<Package, Package['id']>({
      query: (packageId) => ({
        url: `admin/package/${packageId}/deactivate`,
        method: 'POST',
        body: {},
      }),
    }),
    //
    //
    // travel policy registration
    travelPolicyRegistration: builder.mutation<TravelPolicyRegistrationResponse, TravelPolicyRegistration>({
      query: (travelPolicy) => ({
        url: `travel-policy-registration`,
        method: 'POST',
        body: travelPolicy,
      }),
    }),
    // travel policy payment init
    getTravelPolicyPaymentUrl: builder.query<{ url: string }, { paymentType: string; orderId: number }>({
      query: ({ paymentType, orderId }) => ({
        url: `pay/init/payment-type/${paymentType}/order/${orderId}`,
        method: 'GET',
      }),
    }),
    // user active policies
    getUserActivePolicies: builder.query<UserTravelPolicyType, PolicyFilter>({
      query: (filter) => ({
        url: `profile/active-policies?page=${filter.page}&category=${filter.category}&company=${filter.companyId}&insured=${filter.insured}`,
        method: 'GET',
      }),
    }),
    // user closed policies
    getUserClosedPolicies: builder.query<UserTravelPolicyType, PolicyFilter>({
      query: (filter) => ({
        url: `profile/closed-policies?page=${filter.page}&category=${filter.category}&company=${filter.companyId}&insured=${filter.insured}`,
        method: 'GET',
      }),
    }),
    // user pending policies
    getUserPendingPolicies: builder.query<UserTravelPolicyType, PolicyFilter>({
      query: (filter) => ({
        url: `profile/pending-policies?page=${filter.page}&category=${filter.category}&company=${filter.companyId}&insured=${filter.insured}`,
        method: 'GET',
      }),
    }),
    // delete user pending policy
    deleteUserPendingPolicy: builder.mutation<{ message: string }, number>({
      query: (policyId) => ({
        url: `profile/pending-policies/${policyId}`,
        method: 'DELETE',
      }),
    }),
    // create new order for user pending policies
    createUserPendingNewOrder: builder.mutation<
      PendingPolicyNewOrderResponse,
      { policyIds: number[]; targetType: string | undefined }
    >({
      query: (payload) => ({
        url: 'profile/pending-policies/pay',
        method: 'POST',
        body: payload,
      }),
    }),
    // create new order for user pending policies for welcomer policy
    createUserPendingNewOrderWelcomer: builder.mutation<PendingPolicyNewOrderResponse, { policyIds: number[] }>({
      query: (policyIds) => ({
        url: '/profile/welcomer/pending-policies/pay',
        method: 'POST',
        body: policyIds,
      }),
    }),
    // create new order for user pending policies for pet policy
    createUserPendingNewOrderPet: builder.mutation<PendingPolicyNewOrderResponse, { policyIds: number[] }>({
      query: (policyIds) => ({
        url: '/pet/pending-policies/pay',
        method: 'POST',
        body: policyIds,
      }),
    }),
    // create new order for user pending policies for TPL policy
    createUserPendingNewOrderTPL: builder.mutation<PendingPolicyNewOrderResponse, { policyIds: number[] }>({
      query: (policyIds) => ({
        url: '/tpl/pending-policies/pay',
        method: 'POST',
        body: policyIds,
      }),
    }),
    // get user current policies
    getUserCurrentPolicies: builder.query<UserCurrentPolicies, number>({
      query: (mainPolicyId) => ({
        url: `profile/policy/${mainPolicyId}`,
        method: 'GET',
      }),
    }),
    // send verification sms
    sendVerificationSms: builder.mutation<{ response: boolean }, { phone: string }>({
      query: (props) => ({
        url: `send-verification-sms`,
        method: 'POST',
        body: {
          phone: props.phone,
          locale: props.phone.startsWith('995') ? 'ge' : 'en',
        },
      }),
    }),
    // authorization after mobile verification
    authorizationAfterMobileVerification: builder.mutation<
      User,
      { phone: string; email: string; smsCode: number; userId: number }
    >({
      query: (verificationInfo) => ({
        url: `authorization-after-mobile-verification`,
        method: 'POST',
        body: verificationInfo,
      }),
    }),
    // get policies
    getPolicyRegistrationFirstStep: builder.query<PolicyRegistrationFirstStepList[], undefined>({
      query: () => ({
        url: 'travel-abroad-policy-registration-first-step-list',
        method: 'GET',
      }),
    }),
    // get company policies
    // admin/company/1/policy/list?page=1&productCategory=travel&firstName=გუბაზიკო
    getCompanyPolicies: builder.query<
      SoldPolicies,
      {
        pageNumber: number
        personalNumber: string
        productCategory: string
        firstName: string
        lastName: string
        companyId: number
      }
    >({
      query: ({ pageNumber, companyId, productCategory, personalNumber, firstName, lastName }) => ({
        url: `admin/company/${companyId}/policy/list?page=${pageNumber}&productCategory=${productCategory}&personalNumber=${personalNumber}&firstName=${firstName}&lastName=${lastName}`,
        method: 'GET',
      }),
    }),
    // get admin policy detail
    getAdminPolicyById: builder.query<AdminTravelPolicyDetail, number>({
      query: (policyId) => ({
        url: `admin/company-policy/${policyId}`,
        method: 'GET',
      }),
    }),
    // get company payment list
    getAuthorizedCompanies: builder.query<Company[], undefined>({
      query: () => ({
        url: `admin/user/authorized-companies`,
        method: 'GET',
      }),
    }),
    // get tpl and local tpl kist
    getTplAndlocalTplList: builder.query<TplAndLocalTplList[], undefined>({
      query: () => ({
        url: `tpl-and-local-tpl-list`,
        method: 'GET',
      }),
    }),
    // get tpl date text
    getTplStartDateText: builder.query<TplStartDateText, undefined>({
      query: () => ({
        url: `tpl-start-date-text`,
        method: 'GET',
      }),
    }),
    // get tpl insurance period
    getTplInsurancePeriod: builder.query<TplInsurancePeriod[], undefined>({
      query: () => ({
        url: `tpl-insurance-period-list`,
        method: 'GET',
      }),
    }),
    // get tpl currency list
    getTplCurrencyList: builder.query<TplCurrencyList[], undefined>({
      query: () => ({
        url: `tpl-currency-list`,
        method: 'GET',
      }),
    }),
    // get tpl limit list
    getTplLimitList: builder.query<{ value: number }[], undefined>({
      query: () => ({
        url: `tpl-limit-list`,
        method: 'GET',
      }),
    }),
    // travel policy registration
    tplOffer: builder.mutation<TplOfferType[], TplOfferRequest>({
      query: (tplOffer) => ({
        url: `tpl-best-offer`,
        method: 'POST',
        body: tplOffer,
      }),
    }),
    // get admin travel risks
    getAdminTravelRiskList: builder.query<TravelRisk[], undefined>({
      query: () => ({
        url: `admin/travel/risks`,
        method: 'GET',
      }),
    }),
    // get admin travel risk
    getAdminTravelRiskById: builder.query<TravelRisk, number>({
      query: (riskId) => ({
        url: `admin/risk/current/${riskId}`,
        method: 'GET',
      }),
    }),
    // save admin travel risk
    saveTravelRisk: builder.mutation<TravelRisk, Partial<TravelRisk>>({
      query: (travelRisk) => ({
        url:
          travelRisk && 'id' in travelRisk && travelRisk.id && travelRisk.id > 0
            ? `admin/risk/update/${travelRisk.id}`
            : 'admin/risk/store',
        method: 'POST',
        body: travelRisk,
      }),
    }),
    // remove admin travel risk
    removeTravelRisk: builder.mutation<{ message: string }, number>({
      query: (riskId) => ({
        url: `admin/risk/delete/${riskId}`,
        method: 'DELETE',
      }),
    }),
    // get admin travel risks
    getAdminTravelAbroadAddonList: builder.query<TravelAddon[], undefined>({
      query: () => ({
        url: `admin/travel-abroad/addons`,
        method: 'GET',
      }),
    }),
    // get admin travel addon
    getAdminTravelAddonById: builder.query<TravelAddon, number>({
      query: (addonId) => ({
        url: `admin/addon/current/${addonId}`,
        method: 'GET',
      }),
    }),
    // save admin travel addon
    saveTravelAddon: builder.mutation<TravelAddon, Partial<TravelAddon>>({
      query: (travelAddon) => ({
        url:
          travelAddon && 'id' in travelAddon && travelAddon.id && travelAddon.id > 0
            ? `admin/addon/update/${travelAddon.id}`
            : 'admin/addon/store',
        method: 'POST',
        body: travelAddon,
      }),
    }),
    // remove admin travel addon
    removeTravelAddon: builder.mutation<{ message: string }, number>({
      query: (addonId) => ({
        url: `admin/addon/delete/${addonId}`,
        method: 'DELETE',
      }),
    }),
    // get admin franchise list
    getAdminTravelFranchiseList: builder.query<Franchise[], string>({
      query: (slug) => ({
        url: `admin/franchise/${slug}/list`,
        method: 'GET',
      }),
    }),
    // add admin travel franchise
    addAdminTravelFranchise: builder.mutation<{ message: string }, { franchise: number }>({
      query: (franchise) => ({
        url: `admin/franchise/store/travel-abroad-franchise`,
        method: 'POST',
        body: franchise,
      }),
    }),
    // get admin franchise list
    getAdminAuthorizedCompanies: builder.query<AdminAuthorizedCompany[], undefined>({
      query: () => ({
        url: `admin/user/authorized-companies`,
        method: 'GET',
      }),
    }),
    // verify email with emal and token
    verifyEmail: builder.query<{ message: string; stack: string }, { email: string; token: string }>({
      query: ({ email, token }) => ({
        url: `verify-email/${email}/${token}`,
        method: 'GET',
      }),
    }),
    // send email verification link in email
    sendEmailVerificationLink: builder.query<{ message: string }, undefined>({
      query: () => ({
        url: `send-email-verification`,
        method: 'GET',
      }),
    }),
    // admin company users
    getAdminCompanyUsers: builder.query<User[], number>({
      query: (companyId) => ({
        url: `admin/company/${companyId}/user/list`,
        method: 'GET',
      }),
    }),
    // create user in Admin
    storeUserInAdmin: builder.mutation<AdminCompanyUser, AddUser>({
      query: (user) => ({
        url: `admin/company-users/store`,
        method: 'POST',
        body: user,
      }),
    }),
    // update user in Admin
    updateUserInAdmin: builder.mutation<User, { company: Partial<AdminCompanyUser>; userId: number }>({
      query: ({ company, userId }) => ({
        url: `admin/company-users/update/${userId}`,
        method: 'POST',
        body: company,
      }),
    }),
    // remove user in Admin
    removeUserInAdmin: builder.mutation<{ message: string }, number>({
      query: (userId) => ({
        url: `admin/company-users/delete/${userId}`,
        method: 'DELETE',
      }),
    }),
    // save company in admin
    saveCompany: builder.mutation<Partial<AdminAuthorizedCompany>, Partial<AdminCompany>>({
      query: (company) => ({
        url:
          company && 'id' in company && company.id && company.id > 0
            ? `admin/companies/update/${company.id}`
            : 'admin/companies/store',
        method: 'POST',
        body: company,
      }),
    }),
    // get current company by id
    getCurrentCompanyById: builder.query<AdminCompany, number>({
      query: (companyId) => ({
        url: `admin/companies/current/${companyId}`,
        method: 'GET',
      }),
    }),
    // remove company
    removeCompany: builder.mutation<{ message: string }, number>({
      query: (companyId) => ({
        url: `admin/companies/delete/${companyId}`,
        method: 'DELETE',
      }),
    }),
    // travel flow page texts by slug
    getTravelFlowPageTextsBySlug: builder.query<Partial<TravelPagesWording[]>, string>({
      query: (slug) => ({
        url: `admin/travel-flow/${slug}/list`,
        method: 'GET',
      }),
    }),
    // travel flow current item by list id
    getTravelFlowPageTextItemByListItemId: builder.query<Partial<TravelPagesWording>, number>({
      query: (listID) => ({
        url: `admin/travel-flow/${listID}/current`,
        method: 'GET',
      }),
    }),
    // travel flow update current item by id
    updateTravelFlowPageItemByListItemId: builder.mutation<TravelPagesWording[], Partial<TravelPagesWording>>({
      query: (item) => ({
        url: `admin/travel-flow/${item.id}/update`,
        method: 'POST',
        body: item,
      }),
    }),
    // admin travel report list
    getAdminTravelReportList: builder.query<Object[], undefined>({
      query: (_noArg) => ({
        url: `admin/travel-abroad/download/policies-report`,
        method: 'GET',
      }),
    }),
    // admin travel report pdf
    getAdminTravelReportPdf: builder.query<{ url: string }, undefined>({
      query: (_noArg) => ({
        url: `admin/travel-abroad/pdf-reports-file/policies-report`,
        method: 'GET',
      }),
    }),
    // admin travel insurance company report list
    getAdminTravelIncuranceCompanyReportList: builder.query<Object[], number>({
      query: (companyID) => ({
        url: `admin/company/${companyID}/travel-abroad/pdf-reports-file/policies-report`,
        method: 'GET',
      }),
    }),
    // admin travel insurance company report pdf
    getAdminTravelInsuranceCompanyReportPdf: builder.query<{ url: string }, number>({
      query: (companyID) => ({
        url: `admin/company/${companyID}/travel-abroad/download/policies-report`,
        method: 'GET',
      }),
    }),
    // admin web documents
    getAdminWebDocuments: builder.query<AdminWebDocument, string>({
      query: (slug) => ({
        url: `admin/frani-condition/${slug}/detailed`,
        method: 'GET',
      }),
    }),
    // admin web documents store
    storeWebDocument: builder.mutation<AdminWebDocument[], Partial<AdminWebDocument>>({
      query: (item) => ({
        url: `admin/frani-condition/${item.slug}/update`,
        method: 'POST',
        body: item,
      }),
    }),
    // get web documenents for web
    getWebPage: builder.query<Partial<WebPage>, string>({
      query: (slug) => ({
        url: `page/${slug}`,
        method: 'GET',
      }),
    }),
    // get frani pdf file
    getFraniPdf: builder.query<{ url: string }, undefined>({
      query: (_noArg) => ({
        url: `frani-pdf/travel`,
        method: 'GET',
      }),
    }),
    //
    // get information sheet list
    getInformationSheet: builder.query<InformationSheet[], { categoryId: number; attachmentSlug: string }>({
      query: ({ categoryId, attachmentSlug }) => ({
        url: `product-category-attachments/list?productCategoryId=${categoryId}&attachmentSlug=${attachmentSlug}`,
        method: 'GET',
      }),
    }),
    // get current information sheet
    getInformationSheetDetail: builder.query<InformationSheet, number>({
      query: (id) => ({
        url: `product-category-attachments/${id}/current`,
        method: 'GET',
      }),
    }),
    // get travel single select date texts
    getTravelDateTexts: builder.query<TravelDateText, string>({
      query: (slug) => ({
        url: `travel-dates/${slug}`,
        method: 'GET',
      }),
    }),
    // get tpl personal info & first step
    getTplPolicyRegisrationFirstStep: builder.query<TplPolicyRegisrationFirstStep[], undefined>({
      query: (_noArg) => ({
        url: `tpl-policy-registration-first-step`,
        method: 'GET',
      }),
    }),
    // get frani users
    getFraniUsersList: builder.query<User[], undefined>({
      query: (_noArg) => ({
        url: `admin/frani-user-list`,
        method: 'GET',
      }),
    }),
    // get frani user by id
    getFraniUserById: builder.query<User, number>({
      query: (userID) => ({
        url: `admin/current-frani-user/${userID}`,
        method: 'GET',
      }),
    }),
    // save frani user
    storeFraniUser: builder.mutation<User, Partial<StoreFraniUser>>({
      query: (user) => ({
        url:
          user && 'id' in user && user.id && user.id > 0
            ? `admin/update-frani-user/${user.id}`
            : 'admin/store-frani-user',
        method: 'POST',
        body: user,
      }),
    }),
    // frani user deactivation
    franiUserDeactivation: builder.mutation<User, number>({
      query: (userID) => ({
        url: `admin/frani-user-deactivation/${userID}`,
        method: 'POST',
        body: userID,
      }),
    }),
    // frani user activation
    franiUserActivation: builder.mutation<User, number>({
      query: (userID) => ({
        url: `admin/frani-user-activation/${userID}`,
        method: 'POST',
        body: userID,
      }),
    }),
    // get admin travel multi days
    getAdminTravelMultiDayById: builder.query<AdminTravelMultiDay, number>({
      query: (dayID) => ({
        url: `admin/travel-multi-day/${dayID}/current`,
        method: 'GET',
      }),
    }),
    // admin travel multi day store & update
    storeTravelMultiDay: builder.mutation<AdminTravelMultiDay, Partial<AdminTravelMultiDay>>({
      query: (day) => ({
        url:
          day && 'id' in day && day.id && day.id > 0
            ? `admin/travel-multi-day/${day.id}/update`
            : 'admin/travel-multi-day/store',
        method: 'POST',
        body: day,
      }),
    }),
    // remove travel multi day
    removeTravelMultiDay: builder.mutation<AdminTravelMultiDay, number>({
      query: (dayID) => ({
        url: `admin/travel-multi-day/${dayID}/delete`,
        method: 'DELETE',
        body: dayID,
      }),
    }),
    // get main page text list
    getMainPageTextList: builder.query<MainPageText[], undefined>({
      query: (_noArg) => ({
        url: `page/main-page/list`,
        method: 'GET',
      }),
    }),
    // get main page by id
    getMainPageTextById: builder.query<MainPageText, number>({
      query: (pageID) => ({
        url: `admin/page/main-page/${pageID}/current`,
        method: 'GET',
      }),
    }),
    // update main page text
    updateMainPageText: builder.mutation<MainPageText, Partial<MainPageText>>({
      query: (data) => ({
        url: `admin/page/main-page/${data.id}/update`,
        method: 'POST',
        body: data,
      }),
    }),
    // get main page by id
    getFaqList: builder.query<Partial<FaqTypes[]>, undefined>({
      query: (_noArg) => ({
        url: `admin/page/faq/list`,
        method: 'GET',
      }),
    }),
    // FAQ MAIN TEXT UPDATE
    updateFaqMainText: builder.mutation<FaqTypes, Partial<FaqTypes>>({
      query: (data) => ({
        url: `admin/page/main-faq/update`,
        method: 'POST',
        body: data,
      }),
    }),
    // admin FAQ store & update
    storeFAQ: builder.mutation<Partial<FaqTypes>, Partial<FaqTypes>>({
      query: (FAQ) => ({
        url: FAQ && 'id' in FAQ && FAQ.id && FAQ.id > 0 ? `admin/page/faq/${FAQ.id}/update` : 'admin/page/faq/store',
        method: 'POST',
        body: FAQ,
      }),
    }),
    // get FAQ by id
    getFaqById: builder.query<Partial<FaqTypes>, number>({
      query: (FaqID) => ({
        url: `admin/page/faq/${FaqID}`,
        method: 'GET',
      }),
    }),
    // remove FAQ by id
    removeFaqById: builder.mutation<FaqTypes, number>({
      query: (faqID) => ({
        url: `admin/page/${faqID}/delete`,
        method: 'DELETE',
        body: faqID,
      }),
    }),
    // web footer
    getWebFooter: builder.query<Footer[], undefined>({
      query: () => ({
        url: 'menu/footer/list',
        method: 'GET',
      }),
    }),
    // web feedback section
    getFeedback: builder.query<{ registerdUsers: number; paidPolicies: number; registerdCompanies: number }, undefined>(
      {
        query: () => ({
          url: 'feedback',
          method: 'GET',
        }),
      },
    ),
    // tpl policy registration
    tplPolicyRegistration: builder.mutation<undefined, TplPolicyRegistration>({
      query: (tplPolicy) => ({
        url: `tpl-policy-registration`,
        method: 'POST',
        body: tplPolicy,
      }),
    }),
    // get all sold policies with page
    getAllSoldPolicies: builder.query<SoldPolicies, number>({
      query: (pageNumber) => ({
        url: `admin/policies/?page=${pageNumber}`,
        method: 'GET',
      }),
    }),
    // store frani team member
    storeTeamMember: builder.mutation<Partial<OurTeam>, Partial<OurTeam>>({
      query: (member) => ({
        url:
          member && 'id' in member && member.id && member.id > 0
            ? `admin/page/about-us/${member.id}/update`
            : 'admin/page/about-us/store',
        method: 'POST',
        body: member,
      }),
    }),
    // get current team member
    getCurrentTeamMember: builder.query<Partial<OurTeam>, number>({
      query: (memberID) => ({
        url: `admin/page/about-us/${memberID}`,
        method: 'GET',
      }),
    }),
    // get admin about us texts
    getAdminAboutUsTexts: builder.query<Partial<AboutUsType>, undefined>({
      query: (_noArg) => ({
        url: `admin/page/about-us-main-text`,
        method: 'GET',
      }),
    }),
    // update about us texts
    updateAboutUsTexts: builder.mutation<Partial<AboutUsType>, Partial<AboutUsType>>({
      query: (aboutUsTexts) => ({
        url: `admin/page/update-about-us-main-text`,
        method: 'POST',
        body: aboutUsTexts,
      }),
    }),
    // delete franchise
    removeFranchise: builder.mutation<{ message?: string }, number>({
      query: (franchiseID) => ({
        url: `admin/franchise/travel-abroad-franchise/${franchiseID}/delete`,
        method: 'DELETE',
      }),
    }),
    // get admin policy detail
    getAdminPolicyDetail: builder.query<AdminTravelPolicyDetail, number>({
      query: (policyID) => ({
        url: `admin/policies/${policyID}/detailed`,
        method: 'GET',
      }),
    }),
    // get travel limits by currency
    getTravelLimitsByCurrency: builder.query<{ value: number }[], string>({
      query: (currency) => ({
        url: `travel-abroad/get-package-limits-by-currency/${currency}`,
        method: 'GET',
      }),
    }),
    // get travel franchise by limits
    getTravelFranchiseByLimits: builder.query<{ value: number }[], { limit: number; currency: string }>({
      query: ({ limit, currency }) => ({
        url: `travel-abroad-franchises/currency/${currency}?limits=${limit}`,
        method: 'GET',
      }),
    }),
    // Generate Policy Files By Policy Ids
    generatePolicyFilesByPolicyIds: builder.mutation<GeneratePolicyFilesType[], GeneratePolicyFilesProps>({
      query: (policyProps) => ({
        url: `admin/generate-pdf-file-and-store-it-to-bucket-manually`,
        method: 'POST',
        body: policyProps,
      }),
    }),
    // Send Email/Sms To Policy Holder
    sendEmailAndSmsToPolicyHolder: builder.mutation<GeneratePolicyFilesType[], GeneratePolicyFilesProps>({
      query: (policyProps) => ({
        url: `admin/send-sms-and-email-manually`,
        method: 'POST',
        body: policyProps,
      }),
    }),
    // After Payment Steps On DEV
    afterPaymentStepsOnDev: builder.mutation<GeneratePolicyFilesType[], { amount: number; orderId: number }>({
      query: (payload) => ({
        url: `after-payment-steps-on-dev`,
        method: 'POST',
        body: payload,
      }),
    }),
    //After TPL Payment steps on dev
    afterTplPaymentStepsOnDev: builder.mutation<GeneratePolicyFilesType[], { amount: number; orderId: number }>({
      query: (payload) => ({
        url: `after-tpl-payment-steps-on-dev`,
        method: 'POST',
        body: payload,
      }),
    }),
    //After PET Payment steps on dev
    afterPetPaymentStepsOnDev: builder.mutation<GeneratePolicyFilesType[], { amount: number; orderId: number }>({
      query: (payload) => ({
        url: `after-pet-payment-steps-on-dev`,
        method: 'POST',
        body: payload,
      }),
    }),
    // get frani product current emails
    getFraniProductCurrentEmail: builder.query<ProductCategoryEmail, number>({
      query: (productCategoruId) => ({
        url: `admin/frani-emails/${productCategoruId}/current`,
        method: 'GET',
      }),
    }),
    // store  frani product emails
    storeFraniProductEmails: builder.mutation<ProductCategoryEmail, Partial<ProductCategoryEmail>>({
      query: (productEmails) => ({
        url: 'admin/frani-emails/store',
        method: 'POST',
        body: productEmails,
      }),
    }),
    //  update frani product emails
    updateFraniProductEmails: builder.mutation<ProductCategoryEmail, Partial<ProductCategoryEmail>>({
      query: (productEmails) => ({
        url: `admin/frani-emails/${productEmails.productCategoryId}/update`,
        method: 'POST',
        body: productEmails,
      }),
    }),
    // delete frani product mails
    removeFraniProductMails: builder.mutation<{ message?: string }, TravelPackage['id']>({
      query: (emailItemId) => ({
        url: `admin/frani-emails/${emailItemId}/delete`,
        method: 'DELETE',
      }),
    }),
    // get public policy download
    getPublicPolicies: builder.query<PublicPolicy[], string>({
      query: (encode) => ({
        url: `policies/${encode}`,
        method: 'GET',
      }),
    }),
    // get left company departur or arrival
    travelAbrodLeftCompanies: builder.mutation<AdminCompany[], Partial<TravelAbrodLeftCompany>>({
      query: (payload) => ({
        url: `count-travel-companies/${payload.step}`,
        method: 'POST',
        body: payload,
      }),
    }),
    // v2 best offer request
    newAbroadBestOffer: builder.mutation<AbroadOffer[], AbroadOfferRequest>({
      query: (productEmails) => ({
        url: `travel-abroad-best-offer`,
        method: 'POST',
        body: productEmails,
      }),
    }),
    // SGS request
    sgsRequest: builder.mutation<SgsType, SgsRequestType>({
      query: (userInfo) => ({
        url: `sgs-request`,
        method: 'POST',
        body: userInfo,
      }),
    }),
    //tpl flow
    getTplOrLocalTpl: builder.query<TplOrLocalTpl, undefined>({
      query: () => ({
        url: 'flows/tpl-or-local-tpl',
        method: 'GET',
      }),
    }),
    getTplInsurancePeriodData: builder.query<TplInsurancePeriodType, undefined>({
      query: () => ({
        url: 'flows/tpl-insurance-period',
        method: 'GET',
      }),
    }),
    getTplServicesInfo: builder.query<TplServicesInfo, undefined>({
      query: () => ({
        url: 'flows/tpl-extra-service',
        method: 'GET',
      }),
    }),
    getTplCurrencies: builder.query<TplCurrencies, undefined>({
      query: () => ({
        url: 'flows/tpl-currencies',
        method: 'GET',
      }),
    }),
    getTplChoise: builder.query<TplChoise, undefined>({
      query: () => ({
        url: 'flows/tpl-choise',
        method: 'GET',
      }),
    }),
    getTplOfferPageInfo: builder.query<TplBestOffer, undefined>({
      query: () => ({
        url: 'flows/tpl-best-offer',
        method: 'GET',
      }),
    }),
    getTplOffers: builder.query<TplOffers, undefined>({
      query: () => ({
        url: 'flows/tpl-offer',
        method: 'GET',
      }),
    }),
    getTplInsureChoice: builder.query<TplInsureChoice, undefined>({
      query: () => ({
        url: 'flows/tpl-insure-choice',
        method: 'GET',
      }),
    }),
    getTplPersonalInfo: builder.query<TplPersonalInfoType, undefined>({
      query: () => ({
        url: 'flows/tpl-personal-info',
        method: 'GET',
      }),
    }),
    getTplInsuredPersonsInfo: builder.query<TplInsuredPersonsInfo, undefined>({
      query: () => ({
        url: 'flows/tpl-insured-persons-info',
        method: 'GET',
      }),
    }),
    getTplCarPageInfo: builder.query<TplCarInfo, undefined>({
      query: () => ({
        url: 'flows/tpl-car-info',
        method: 'GET',
      }),
    }),
    getTplCheckInfo: builder.query<TplCheckInfo, undefined>({
      query: () => ({
        url: 'flows/tpl-check-info',
        method: 'GET',
      }),
    }),
    getTplContactInfo: builder.query<TplContactInfo, undefined>({
      query: () => ({
        url: 'flows/tpl-contact-info',
        method: 'GET',
      }),
    }),
    // get tpl product by id
    getTplProductById: builder.query<TplProduct, number>({
      query: (productId) => ({
        url: `admin/tpl-products/${productId}/details`,
        method: 'GET',
      }),
    }),
    //tpl packages
    createTplPackage: builder.mutation<
      TplPackage,
      {
        productId: number
        translations: TranslationsField<{ title: string }>
        limitId: number
        currencyId: number
        paymentScheduleTypeIds: number[]
      }
    >({
      query: (tplPackage) => ({
        url: 'admin/tpl-packages/store',
        method: 'POST',
        body: tplPackage,
      }),
    }),
    // update tpl package
    updateTplPackage: builder.mutation<TplPackage, TplPackage>({
      query: (tplPackage) => ({
        url: `admin/tpl-packages/${tplPackage.id}/update`,
        method: 'POST',
        body: tplPackage,
      }),
    }),
    // delete tpl package
    deleteTplPackage: builder.mutation<{ message?: string }, TplPackage['id']>({
      query: (tplPackageId) => ({
        url: `admin/tpl-packages/delete/${tplPackageId}`,
        method: 'DELETE',
      }),
    }),
    //
    createTplProduct: builder.mutation<{ message: string }, { companyId: number; productCategoryId: number }>({
      query: (tplProduct) => ({
        url: 'admin/tpl-product/store',
        method: 'POST',
        body: tplProduct,
      }),
    }),
    //
    getTplPackageById: builder.query<TplPackage, number>({
      query: (tplPackageId) => ({
        url: `admin/tpl-packages/${tplPackageId}/details`,
        method: 'GET',
      }),
    }),
    //
    getTplLeftCompanies: builder.query<TplLeftCompanies, { stepName: string; query: string }>({
      query: ({ stepName, query }) => ({
        url: `tpl/companies/filter/steps/${stepName}?${query}`,
        method: 'GET',
      }),
    }),
    //
    getProductDetailInfoByType: builder.query<ProductDetailType, string>({
      query: (type) => ({
        url: `product-detail-information/${type}`,
        method: 'GET',
      }),
    }),
    //
    getProductDetailsCompanyByType: builder.query<AdminCompany[], string>({
      query: (type) => ({
        url: `product-detail-information/companies/${type}`,
        method: 'GET',
      }),
    }),
    //
    getTplOffer: builder.query<TplOfferResponse[], { query: string }>({
      query: ({ query }) => ({
        url: `tpl/companies/filter/best-offer?${query}`,
        method: 'GET',
      }),
    }),
    //
    getTplPaymentMethods: builder.query<TplPaymentMethod[], undefined>({
      query: (_noArd) => ({
        url: `payment-schedule-types/list`,
        method: 'GET',
      }),
    }),
    // business insurance
    sendBusinessHealthInfo: builder.mutation<BusinessHealthInfo, Partial<BusinessHealthInfo>>({
      query: (payload) => ({
        url: `corporate-insurance-offer`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    sendBusinessCarInfo: builder.mutation<BusinessCarInfo, Partial<BusinessCarInfo>>({
      query: (payload) => ({
        url: `corporate-insurance-offer`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    sendBusinessPropertyInfo: builder.mutation<BusinessPropertyInfo, Partial<BusinessPropertyInfo>>({
      query: (payload) => ({
        url: `corporate-insurance-offer`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    sendBusinessOtherInfo: builder.mutation<BusinessOtherInfo, Partial<BusinessOtherInfo>>({
      query: (payload) => ({
        url: `corporate-insurance-offer`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    getTplLimitsByCurrency: builder.query<number[], string>({
      query: (currency) => ({
        url: `tpl/packages/limits/${currency}`,
        method: 'GET',
      }),
    }),
    //
    tplCompareOffers: builder.mutation<TplCompareResponse, Partial<TplCompareRequest>>({
      query: (payload) => ({
        url: `tpl/companies/filter/compare`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    getTplCarInfo: builder.query<
      TplCarInfoResponse,
      { registrationNumber: string; privateNumber: string; relatedPrivateNumber: string | null }
    >({
      query: (props) => ({
        url: `tpl/cars?registrationNumber=${props.registrationNumber}&privateNumber=${props.privateNumber}&relatedPrivateNumber=${props.relatedPrivateNumber}`,
        method: 'GET',
      }),
    }),
    //
    getTplExtraServices: builder.query<PackageServices[], undefined>({
      query: (_noArg) => ({
        url: `tpl/package-services`,
        method: 'GET',
      }),
    }),
    //
    registerTplPolicy: builder.mutation<TravelPolicyRegistrationResponse, Partial<RegisterTplPolicyRequest>>({
      query: (payload) => ({
        url: `tpl/policies`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    tplPayment: builder.mutation<
      { paymentGatewayUrl: string },
      { orderId: number; paymentType: string; recurrentPaymentApprove: boolean }
    >({
      query: (payload) => ({
        url: `tpl/payments`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    getTplPaymentSchedule: builder.query<TplPaymentSchedule, { orderId: number }>({
      query: (props) => ({
        url: `tpl/orders/${props.orderId}/scheduled-payments`,
        method: 'GET',
      }),
    }),
    //
    getTplTransactions: builder.query<TplTransactions[], { orderId: number }>({
      query: (props) => ({
        url: `tpl/orders/${props.orderId}/payments`,
        method: 'GET',
      }),
    }),
    //
    welcomerLeftCompanies: builder.mutation<AdminCompany[], Partial<WelcomerLeftCompany>>({
      query: (payload) => ({
        url: `count-welcomer-companies/${payload.step}`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    WelcomerLeftCompaniesLastStep: builder.mutation<
      { uniqueCompanies: AdminCompany[]; packageData: Package[]; count: number },
      Partial<WelcomerLeftCompany>
    >({
      query: (payload) => ({
        url: `count-welcomer-companies/${payload.step}`,
        method: 'POST',
        body: payload,
      }),
    }),
    // get welcomer offers
    welcomerBestOffer: builder.mutation<WelcomerOffers[], Partial<WelcomerSearchOffer>>({
      query: (payload) => ({
        url: `count-welcomer-companies/best-offer`,
        method: 'POST',
        body: payload,
      }),
    }),
    // get welcomer pages info
    getWelcomerPagesInfo: builder.query<WelcomerPagesInfo, string>({
      query: (slug) => ({
        url: `flows/${slug}`,
        method: 'GET',
      }),
    }),
    // get welcomer currency list
    getWelcomerCurrencyList: builder.query<WelcomerCurrencyList[], undefined>({
      query: () => ({
        url: `welcomer/currencies`,
        method: 'GET',
      }),
    }),
    // get welcomer limit list
    getWelcomerLimitList: builder.query<WelcomerLimitsList[], undefined>({
      query: () => ({
        url: `welcomer/limits`,
        method: 'GET',
      }),
    }),
    // welcomer policy registration
    welcomerPolicyRegistration: builder.mutation<WelcomerPolicyRegistrationResponse, WelcomerPolicyRegistration>({
      query: (welcomerPolicy) => ({
        url: `welcomer-policy-registration`,
        method: 'POST',
        body: welcomerPolicy,
      }),
    }),
    //
    getUserTransactions: builder.query<UserTransactions, Partial<Transation>>({
      query: ({ companyId, insuranceType, insuredName, status, page, limit }) => ({
        url: `profile/orders?insuranceType=${insuranceType}&companyId=${companyId}&insuredName=${insuredName}&status=${status}&page=${page}&limit=${limit}`,
        method: 'GET',
      }),
    }),
    //
    getUserPolicyTransactions: builder.query<UserTransactions, number>({
      query: (policyId) => ({
        url: `profile/orders?policyId=${policyId}`,
        method: 'GET',
      }),
    }),
    //
    getPetPageInfo: builder.query<PetPageInfo, string>({
      query: (slug) => ({
        url: `flows/${slug}`,
        method: 'GET',
      }),
    }),
    //
    getPetBreeds: builder.query<PetBreed[], undefined>({
      query: () => ({
        url: `pet/breeds`,
        method: 'GET',
      }),
    }),
    //
    getPetCategories: builder.query<PetCategories[], undefined>({
      query: () => ({
        url: 'pet/categories',
        method: 'GET',
      }),
    }),
    //
    petLeftCompanies: builder.mutation<TplLeftCompanies, Partial<PetLeftCompany>>({
      query: (payload) => ({
        url: `pet/companies/filter/steps/${payload.step}`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    petBestOffer: builder.mutation<PetBestOffer[], Partial<PetOfferPayload>>({
      query: (payload) => ({
        url: `pet/companies/filter/best-offer`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    petPolicyGeneration: builder.mutation<TravelPolicyRegistrationResponse, Partial<RegisterPetPolicy>>({
      query: (petPolicy) => ({
        url: `pet/policies`,
        method: 'POST',
        body: petPolicy,
      }),
    }),
    //
    petPayment: builder.mutation<
      { paymentGatewayUrl: string },
      { orderId: number; paymentType: string; recurrentPaymentApprove: boolean }
    >({
      query: (payload) => ({
        url: `pet/payments`,
        method: 'POST',
        body: payload,
      }),
    }),
    //
    getPetServices: builder.query<PetServices[], { query: string }>({
      query: ({ query }) => ({
        url: `pet/resources/services?${query}`,
        method: 'GET',
      }),
    }),
    //
    getPetPaymentSchedule: builder.query<TplPaymentSchedule, { orderId: number }>({
      query: (props) => ({
        url: `pet/orders/${props.orderId}/scheduled-payments`,
        method: 'GET',
      }),
    }),
    //
    getPetTransactions: builder.query<TplTransactions[], { orderId: number }>({
      query: (props) => ({
        url: `pet/orders/${props.orderId}/payments`,
        method: 'GET',
      }),
    }),
    //
    getFraniPartners: builder.query<FraniPartnerType[], undefined>({
      query: (_Arg) => ({
        url: `partners`,
        method: 'GET',
      }),
    }),
    //
    getTplPaymentDiscount: builder.query<DiscountedPaymentList, { query: string }>({
      query: ({ query }) => ({
        url: `tpl/payments/discount?${query}`,
        method: 'GET',
      }),
    }),
    //
    getTravelPaymentDiscount: builder.mutation<DiscountList, TravelAboardDiscountPost>({
      query: (offerParams) => ({
        url: 'travel/payments/discount',
        method: 'POST',
        body: offerParams,
      }),
    }),
    //
    getUserPromoCodes: builder.query<PromoCode[], { userId: number }>({
      query: (props) => ({
        url: `promo-codes/users/${props.userId}`,
        method: 'GET',
      }),
    }),
    //
    getWelcomerPaymentDiscount: builder.mutation<DiscountList, WelcomerSearchDiscount>({
      query: (offerParams) => ({
        url: 'welcomer/payments/discount',
        method: 'POST',
        body: offerParams,
      }),
    }),
    //
    getPetPaymentDiscount: builder.query<DiscountedPaymentList, { query: string }>({
      query: ({ query }) => ({
        url: `pet/payments/discount?${query}`,
        method: 'GET',
      }),
    }),
    //
    getLandingSlides: builder.query<LandingSlide[], undefined>({
      query: (_noArg) => ({
        url: `slides`,
        method: 'GET',
      }),
    }),
    //
  }),
})

export default api
