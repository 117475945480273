import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnabledLanguage } from '../domain/types'
import { detectUserLanguage } from './langStorage'

export type RegionType = {
  city: string
  country_calling_code: string
  country_capital: string
  country_code: string
  country_code_iso3: string
  country_name: string
  country_population: number
  country_tld: string
  currency: string
  in_eu: boolean
  ip: string
  languages: string
  timezone: string
}
//
export type AppState = {
  lang: EnabledLanguage
  region: RegionType | null
  isLoading: boolean
  searchKeyword: string
  isProduction: boolean
  showPhoneNumTimer: boolean
  showPhoneNumResend: boolean
  phoneTimerCount: number
}
//
const Initial: AppState = {
  lang: detectUserLanguage(),
  region: null,
  isLoading: false,
  searchKeyword: '',
  isProduction: false,
  showPhoneNumTimer: false,
  showPhoneNumResend: false,
  phoneTimerCount: 60,
}
//
const slice = createSlice({
  name: 'appSlice',
  initialState: Initial,
  reducers: {
    //
    clearPhoneInfo: (state, actionPayload: PayloadAction<undefined>) => {
      return {
        ...state,
        showPhoneNumTimer: false,
        showPhoneNumResend: false,
        phoneTimerCount: 60,
      }
    },
    setShowPhoneNumResend: (state: AppState, { payload }: PayloadAction<AppState['showPhoneNumResend']>) => {
      state.showPhoneNumResend = payload
    },
    setPhoneTimerCount: (state: AppState, { payload }: PayloadAction<AppState['phoneTimerCount']>) => {
      state.phoneTimerCount = payload
    },
    setShowPhoneNumTimer: (state: AppState, { payload }: PayloadAction<AppState['showPhoneNumTimer']>) => {
      state.showPhoneNumTimer = payload
    },
    //
    setRegion: (state: AppState, { payload: region }: PayloadAction<AppState['region']>) => {
      state.region = region
    },
    //
    setLanguage: (state: AppState, { payload: lang }: PayloadAction<EnabledLanguage>) => {
      state.lang = lang
    },
    //
    setLoading: (state: AppState, { payload: isLoading }: PayloadAction<AppState['isLoading']>) => {
      state.isLoading = isLoading
    },
    //
    setIsProduction: (state: AppState, { payload }: PayloadAction<AppState['isProduction']>) => {
      state.isProduction = payload
    },
    //
    setSearchKeyword: (
      // for global header search
      state: AppState,
      { payload: searchKeyword }: PayloadAction<AppState['searchKeyword']>,
    ) => {
      state.searchKeyword = searchKeyword
    },
  },
})

export const {
  setLanguage,
  setRegion,
  setLoading,
  setSearchKeyword,
  setIsProduction,
  setShowPhoneNumTimer,
  setShowPhoneNumResend,
  setPhoneTimerCount,
  clearPhoneInfo,
} = slice.actions

export default slice.reducer
